// import React, { useState, useEffect } from 'react';

// function GeneralInfo(productInfo) {
//     const [title, setTitle] = useState(productInfo.title);
//     const [description, setDescription] = useState(productInfo.body_html);
//     const [handle, setHandle] = useState(productInfo.handle);
//     const [status, setStatus] = useState(productInfo.status);

//     useEffect(() => {
//         if (productInfo) {
//             setTitle(productInfo.title || '');
//             setDescription(productInfo.body_html || '');
//             setHandle(productInfo.handle || '');
//             setStatus(productInfo.status || '');
//         }

//         // console.log('Current title:', title)
//         // console.log('Current description:', description)
//         // console.log('Current handle:', handle)
//         // console.log('Current Status:', status)

//     }, [productInfo]);

//     const handleTitleChange = (event) => {
//         setTitle(event.target.value);
//     };

//     const handleDescriptionChange = (event) => {
//         setDescription(event.target.value);
//     };

//     const handleHandleChange = (event) => {
//         setHandle(event.target.value);
//     };

//     const handleStatusChange = (event) => {
//         setStatus(event.target.value);
//     };

//     console.log('Changed title:', title)
//     console.log('Changed description:', description)
//     console.log('Changed handle:', handle)
//     console.log('Changed Status:', status)


//     return (
//         <div id='general-info-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//             <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">General Information</h3>

//             {/* Column 1 */}
//             <div id="general-info-container" className='grid grid-cols-2 gap-4'>
//                 <div>
//                     <div className="mb-5">
//                         <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
//                         <input type="text" value={title} id='title' onChange={handleTitleChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//                     </div>

//                     <div className="mb-5">
//                         <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
//                         <textarea type="text" rows="5" value={description} id='description' onChange={handleDescriptionChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//                     </div>
//                 </div>

//                 {/* column 2 */}
//                 <div>
//                     <div className="mb-5 font-normal text-gray-700 dark:text-gray-400">
//                         <label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ID</label>
//                         <input type="text" value={productInfo.id} disabled readOnly id="id" aria-label="disabled input" className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>
//                     </div>

//                     <div className="mb-5">
//                         <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
//                         <select id="status" onChange={handleStatusChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
//                             <option value="active">Active</option>
//                             <option value="draft">Draft</option>
//                         </select>
//                     </div>

//                     <div className="mb-5">
//                         <label htmlFor="handle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Handle</label>
//                         <input type="text" value={handle} id='handle' onChange={handleHandleChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//                     </div>

//                     {/* <button type="button" className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Add Tag</button> */}

//                 </div>
//             </div>
//         </div>
//     );
// }

// export default GeneralInfo;

import React, { useState, useEffect } from 'react';


function GeneralInfo({ productInfo }) {
    const [title, setTitle] = useState(productInfo?.title || '');
    const [description, setDescription] = useState(productInfo?.body_html || '');
    const [handle, setHandle] = useState(productInfo?.handle || '');
    const [status, setStatus] = useState(productInfo?.status || '');

    useEffect(() => {
        if (productInfo) {
            setTitle(productInfo.title || '');
            setDescription(productInfo.body_html || '');
            setHandle(productInfo.handle || '');
            setStatus(productInfo.status || '');
        }
    }, [productInfo]);

        // console.log('Current title:', title)
        // console.log('Current description:', description)
        // console.log('Current handle:', handle)
        // console.log('Current Status:', status)

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleHandleChange = (event) => {
        setHandle(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    // console.log('Changed title:', title)
    // console.log('Changed description:', description)
    // console.log('Changed handle:', handle)
    // console.log('Changed Status:', status)


    return (
        <div id='general-info-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">General Information</h3>

            {/* Column 1 */}
            <div id="general-info-container" className='grid grid-cols-2 gap-4'>
                <div>
                    <div className="mb-5">
                        <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                        <input type="text" value={title} id='title' onChange={handleTitleChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>

                    <div className="mb-5">
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                        <textarea type="text" rows="5" value={description} id='description' onChange={handleDescriptionChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                {/* column 2 */}
                <div>
                    <div className="mb-5 font-normal text-gray-700 dark:text-gray-400">
                        <label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ID</label>
                        <input type="text" value={productInfo?.id} disabled readOnly id="id" aria-label="disabled input" className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>
                    </div>

                    <div className="mb-5">
                        <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                        <select id="status" onChange={handleStatusChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="active">Active</option>
                            <option value="draft">Draft</option>
                        </select>
                    </div>

                    <div className="mb-5">
                        <label htmlFor="handle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Handle</label>
                        <input type="text" value={handle} id='handle' onChange={handleHandleChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>

                    {/* <button type="button" className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Add Tag</button> */}

                </div>
            </div>
        </div>
    );
}

export default GeneralInfo;

