import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { productAdded } from './newProductSlice';

const Step3 = () => {
    // Component State
    const [variants, setVariants] = useState([]);

    // Hooks
    const newProduct = useSelector((state) => state.newProduct); // Get the current state of the product from redux, Must initialize first
    const dispatch = useDispatch(); // Get dispatch function to dispatch actions to redux slice
    const navigate = useNavigate(); // Get navigation function for navigating between steps
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm(); // Get react-hook-form methods for handling form data and validation

    useEffect(() => {
        // Get stored data from local storage when the component mounts
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {};

        if (storedData.product && storedData.product.variants.length > 0) {
            const existingVariants = storedData.product.variants || [];
            setVariants(existingVariants);
        } else {
            // Set a default variant if no variants are stored
            setVariants([
                {
                    id: uuidv4(), // Generate a unique ID
                    option1: newProduct.product.title,
                    option2: "",
                    option3: "No Delay",
                    price: "0.00",
                    sku: "",
                    weight: 0, // Convert to float
                    weight_unit: "lb",
                    inventory_management: "shopify",
                    inventory_policy: "continue",
                    inventory_quantity: 10000,
                }
            ]);
        }
    }, [newProduct.product.title]); // Add dependency for newProduct.product.title: whenever the product title changes, this will re-render


    // Event Handlers and Helper Functions

    // Function to add a new variant to local form state
    const addVariant = () => {
        const newVariant = {
            id: uuidv4(), // Generate a unique ID
            option1: newProduct.product.title,
            option2: "",
            option3: "No Delay",
            price: "0.00",
            sku: "",
            weight: 0, // Convert to float
            weight_unit: "lb",
            inventory_management: "shopify",
            inventory_policy: "continue",
            inventory_quantity: 10000,
        };
        setVariants([...variants, newVariant]);
    };

    const updateVariant = (id, field, value) => {
        const updatedVariants = variants.map(variant => {
            if (variant.id === id) {
                return { ...variant, [field]: value }; // Update the specified field for the matching variant
            }
            return variant;
        });
        setVariants(updatedVariants);
    };

    // Function to delete a variant
    const deleteVariant = (id) => {
        const updatedVariants = variants.filter(variant => variant.id !== id);
        setVariants(updatedVariants);
    };

    const onSubmit = (data) => {

        // Update newProduct.product object local form state
        const updatedProductData = {
            ...newProduct.product,
        };

        // Create an array of variant objects based on the form data
        // const variantArray = [
        const updatedVariantsArray = [
            ...variants.map((variant) => ({
                id: variant.id,
                option1: data[`variant-${variant.id}-option1`] || newProduct.product.title, // Variant Name
                option2: data[`variant-${variant.id}-option2`] || "", // Variant Size
                option3: "No Delay", // Variant Shipping Delay
                price: data[`variant-${variant.id}-price`] || "0.00",
                sku: data[`variant-${variant.id}-sku`] || "",
                weight: parseFloat(data[`variant-${variant.id}-weight`]) || 0, // Convert to float
                weight_unit: "lb",
                inventory_management: "shopify",
                inventory_policy: "continue",
                inventory_quantity: 10000,
            }))
        ];

        // Combine the new variant array with the existing variants
        // const updatedVariantArray = [...newProduct.product.variants, ...variantArray];

        // Dispatch and action to update newProduct redux global state
        dispatch(productAdded({
            product: updatedProductData,
            variants: updatedVariantsArray,
        }));

        // Save the submitted form data to local storage
        saveFormDataToLocalStorage(updatedProductData, updatedVariantsArray);

        // Navigate to the next step
        navigate("/new-product/step4", { replace: true });
    };

    const goBack = () => {

        // Update newProduct.product object local form state
        const updatedProductData = {
            ...newProduct.product,
        };

        // Create an array of variant objects based on the form data
        // const variantArray = [
        // const updatedVariantsArray = [
        //     ...variants.map((variant) => ({
        //         id: variant.id,
        //         option1: data[`variant-${variant.id}-option1`] || newProduct.product.title, // Variant Name
        //         option2: data[`variant-${variant.id}-option2`] || "", // Variant Size
        //         option3: "No Delay", // Variant Shipping Delay
        //         price: data[`variant-${variant.id}-price`] || "0.00",
        //         sku: data[`variant-${variant.id}-sku`] || "",
        //         weight: parseFloat(data[`variant-${variant.id}-weight`]) || 0, // Convert to float
        //         weight_unit: "lb",
        //         inventory_management: "shopify",
        //         inventory_policy: "continue",
        //         inventory_quantity: 10000,
        //     }))
        // ];

        const updatedVariantsArray = variants.map((variant) => ({
            id: variant.id,
            option1: getValues(`variant-${variant.id}-option1`) || newProduct.product.title, // Variant Name
            option2: getValues(`variant-${variant.id}-option2`) || "", // Variant Size
            option3: "No Delay", // Variant Shipping Delay
            price: getValues(`variant-${variant.id}-price`) || "0.00",
            sku: getValues(`variant-${variant.id}-sku`) || "",
            weight: parseFloat(getValues(`variant-${variant.id}-weight`)) || 0, // Convert to float
            weight_unit: "lb",
            inventory_management: "shopify",
            inventory_policy: "continue",
            inventory_quantity: 10000,
        }));

        // Dispatch and action to update newProduct redux global state
        dispatch(productAdded({
            product: updatedProductData,
            variants: updatedVariantsArray,
        }));


        // Save updated local form state to localStorage
        saveFormDataToLocalStorage(updatedProductData, updatedVariantsArray)

        navigate("/new-product/step2", { replace: true });
    };

    const saveFormDataToLocalStorage = (updatedProductData, updatedVariantsArray) => {
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {}
        localStorage.setItem('newProduct', JSON.stringify(
            {
                product: {
                    vendor: 'ZYMO RESEARCH',
                    status: 'active',
                    ...newProduct.product,
                    ...updatedProductData,
                    images: [...storedData.product.images] || [],
                    variants: updatedVariantsArray || [],
                    options: [
                        {
                            name: 'Name'
                        },
                        {
                            name: 'Size'
                        },
                        {
                            name: 'Shipping Delay',
                            values: [
                                'No Delay'
                            ]
                        }
                    ],
                    metafields: [...storedData.product.metafields] || [],
                }
            }
        ));
    };


    return (

        <section id="step-3" className="max-w-screen-md mx-auto p-6 m-16 bg-gray-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div id="step-3-container" className="mx-auto max-w-2xl">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div className="pb-8">
                        <h2 className="text-xl font-bold mb-1">Product Variants</h2>
                        <p className="mb-8 text-sm">If this product does not have multiple variants, please fill out the details for the main product in this section.</p>
                        {/* Render input fields for each variant */}
                        {variants.map((variant, index) => (
                            <div key={variant.id} className="space-y-2 mb-5 p-5 border border-gray-300 rounded-lg bg-gray-300">
                                {/* Variant Name */}
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                                <p className="pb-6 block mb-2 font-bold text-md">Variant {index + 1}</p>
                                    <div className="pb-8 md:col-span-2">
                                        <label htmlFor={`variant-${variant.id}-option1`} className="block mb-2 font-medium">Variant Name</label>
                                        <input
                                            type='text'
                                            id={`variant-${variant.id}-option1`}
                                            className="w-full rounded py-2 px-3"
                                            value={variant.option1}
                                            {...register(`variant-${variant.id}-option1`, {
                                                required: "Please enter a variant name"
                                            })}
                                            onChange={(e) => updateVariant(variant.id, 'option1', e.target.value)}
                                        />
                                        {errors[`variant-${variant.id}-option1`] && <p className="text-red-500">{errors[`variant-${variant.id}-option1`].message}</p>}
                                    </div>
                                    {/* Other Variant Details */}
                                    <div className="pb-8 col-span-1">
                                        <label htmlFor={`variant-${variant.id}-option2`} className="block mb-2 font-medium">Variant Size</label>
                                        <input
                                            type='text'
                                            id={`variant-${variant.id}-option2`}
                                            className="w-full border rounded py-2 px-3"
                                            value={variant.option2}
                                            {...register(`variant-${variant.id}-option2`, { required: "Please enter a variant size" })}
                                            onChange={(e) => updateVariant(variant.id, 'option2', e.target.value)}
                                        />
                                        {errors[`variant-${variant.id}-option2`] && <p className="text-red-500">{errors[`variant-${variant.id}-option2`].message}</p>}
                                    </div>

                                    <div className="pb-8 col-span-1">
                                        <label htmlFor={`variant-${variant.id}-price`} className="block mb-2 font-medium">Variant Price</label>
                                        <input
                                            type='number'
                                            step=".01"
                                            placeholder="0.00"
                                            id={`variant-${variant.id}-price`}
                                            className="w-full  border rounded py-2 px-3"
                                            value={variant.price}
                                            {...register(`variant-${variant.id}-price`, { required: "Please enter a variant price" })}
                                            onChange={(e) => updateVariant(variant.id, 'price', e.target.value)}
                                        />
                                        {errors[`variant-${variant.id}-price`] && <p className="text-red-500">{errors[`variant-${variant.id}-price`].message}</p>}
                                    </div>

                                    <div className="pb-8 col-span-1">
                                        <label htmlFor={`variant-${variant.id}-sku`} className="block mb-2 font-medium">SKU</label>
                                        <input
                                            type='text'
                                            id={`variant-${variant.id}-sku`}
                                            className="w-full border rounded py-2 px-3"
                                            value={variant.sku}
                                            {...register(`variant-${variant.id}-sku`, { required: "Please enter a unique variant sku number" })}
                                            onChange={(e) => updateVariant(variant.id, 'sku', e.target.value)}
                                        />
                                        {errors[`variant-${variant.id}-sku`] && <p className="text-red-500">{errors[`variant-${variant.id}-sku`].message}</p>}
                                    </div>

                                    <div className="pb-8 col-span-1">
                                        <label htmlFor={`variant-${variant.id}-weight`} className="block mb-2 font-medium">Weight in lb.</label>
                                        <input
                                            type='number'
                                            step=".001"
                                            id={`variant-${variant.id}-weight`}
                                            className="w-full border rounded py-2 px-3"
                                            value={variant.weight}
                                            {...register(`variant-${variant.id}-weight`)}
                                            onChange={(e) => updateVariant(variant.id, 'weight', e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/* Button to delete a variant if it's not index 0 */}
                                {index !== 0 && (
                                    <button type="button" onClick={() => deleteVariant(variant.id)} className="p-2 text-red-500 hover:text-red-600 font-bold">Delete</button>
                                )}
                            </div>
                        ))}

                        {/* Button to add a new variant */}
                        <button type="button" onClick={addVariant} className="mb-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            Add a new variant
                        </button>
                    </div>


                    <div className="flex justify-between">
                        {/* Back to Home button */}
                        <button onClick={goBack} className="bg-gray-500 hover:bg-gray-400 text-white hover:text-gray-100 font-bold py-2 px-4 rounded">
                            Go Back
                        </button>
                        {/* Save & Next button */}
                        <button type="submit" className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                            Save & Next
                        </button>
                    </div>
                </form>
            </div>
        </section >
    );
};

export default Step3;
