import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { addNewProductToShopify } from './newProductSlice';

const ConfirmationPage = () => {

    const newProduct = useSelector((state) => state.newProduct) // this will pass the current state of the product object from the last step

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm()


    const onSubmit = async (data) => {
        try {

            // Logic to add new product object to store
            const productResponse = await dispatch(addNewProductToShopify({ ...newProduct }))
            // const productResponse = await dispatch(addNewProductToShopify({ ...newProduct.product }))
            console.log('In Confirmation page submit function, after addNewProductToShopify completed, here is productResponse:', productResponse);

            // Logic to add new metafield objects from metafields array to store


            navigate("/new-product/thankyou", { replace: true })

        } catch (error) {
            console.error('In Confirmation Page, error adding new product', error)
            throw error
        }
    }

    return (
        <section className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
            <h2 className="text-2xl font-semibold mb-4">Confirmation Page</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <p className="text-lg font-semibold">Product Details:</p>
                    <p className="text-gray-700">Name: {newProduct.product.title}</p>
                    <p className="text-gray-700">Description: {newProduct.product.body_html}</p>
                    {/* <img src={newProduct.product.images.map(image => image.src)} alt={newProduct.product.images.map(image => image.alt)}/> */}
                </div>

                <div className="mb-4">
                    <p className="text-lg font-semibold">Variants:</p>
                    <ul>
                        {newProduct.product.variants ? (
                            newProduct.product.variants.map((variant, index) => (
                                <li key={index} className="text-gray-700">
                                    <p>Title: {variant.title}</p>
                                    <p>Price: {variant.price}</p>
                                    <p>Sku: {variant.sku}</p>
                                </li>
                            ))
                        ) : (
                            <li>Loading...</li>
                        )}
                    </ul>
                </div>


                <div className="mb-4">
                    <p className="text-lg font-semibold">Metafields:</p>
                    <ul>
                        {newProduct.product.metafields ? (
                            newProduct.product.metafields.map((metafield, index) => (
                                <li key={index} className="text-gray-700">
                                    <p>Namespace: {metafield.namespace}</p>
                                    <p>Key: {metafield.key}</p>
                                    <p>Value: {metafield.value}</p>
                                </li>
                            ))
                        ) : (
                            <li>Loading...</li>
                        )}
                    </ul>
                </div>

                {/* Submit button */}
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Send POST Request</button>
            </form>
        </section>
    )
}

export default ConfirmationPage;