import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { productAdded } from './newProductSlice';

const Step2 = () => {
    // Component State 
    const [highlights, setHighlights] = useState([]);
    const [charCount, setCharCount] = useState(0);


    // Hooks
    const newProduct = useSelector((state) => state.newProduct);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();

    useEffect(() => {
        // Get stored data from local storage when the component mounts
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {};

        if (storedData.product && storedData.product.metafields) {
            setValue('seo_title', storedData.product.metafields.find(metafield => metafield.namespace === "global" && metafield.key === "title_tag")?.value || '');
            setValue('seo_description', storedData.product.metafields.find(metafield => metafield.namespace === "global" && metafield.key === "description_tag")?.value || '');

            const existingHighlights = storedData.product.metafields.filter(metafield => metafield.namespace === "highlight") || [];
            setHighlights(existingHighlights);
        }
    }, []);


    // Event handlers and helper functions
    const handleTextareaChange = (e) => {
        const value = e.target.value;
        setCharCount(value.length);
        setValue('seo_description', value);
    };

    const addHighlight = () => {
        const newHighlight = {
            id: uuidv4(),
            namespace: 'highlight',
            key: '',
            value: '',
            type: "string"
        };
        setHighlights([...highlights, newHighlight]);
    };

    const updateHighlight = (id, value) => {
        const updatedHighlights = highlights.map(highlight => {
            if (highlight.id === id) {
                return { ...highlight, value };
            }
            return highlight;
        });
        setHighlights(updatedHighlights);
    };

    const deleteHighlight = (id) => {
        const updatedHighlights = highlights.filter(highlight => highlight.id !== id);
        setHighlights(updatedHighlights);
    };

    const onSubmit = (data) => {
        // Update product data in local form state
        const updatedProductData = {
            ...newProduct.product,
        };

        // Access the form values using getValues and Update metafields array in local form state
        const { seo_description } = getValues();
        const updatedMetafieldsArray = [
            // ...newProduct.product.metafields,
            { namespace: 'global', key: 'title_tag', type: 'string', value: `${newProduct.product.title} | ZYMO RESEARCH` },
            { namespace: 'global', key: 'description_tag', type: 'string', value: seo_description },
            ...highlights.map((highlight, index) => ({
                id: highlight.id,
                namespace: "highlight",
                key: `highlight-${String.fromCharCode(97 + index)}`,
                value: highlight.value,
                type: "string",
            })),
        ];

        // Dispatch action to update newProduct redux global state
        dispatch(productAdded({
            product: updatedProductData,
            metafields: updatedMetafieldsArray,
        }));

        // Save updated local form state to localStorage
        saveFormDataToLocalStorage(updatedProductData, updatedMetafieldsArray)

        // Navigate to the next step
        navigate("/new-product/step3", { replace: true });
    };

    const goBack = () => {
        // Update product data in local form state
        const updatedProductData = {
            ...newProduct.product,
        };

        // Access the form values using getValues and Update metafields array in local form state
        const { seo_description } = getValues();
        const updatedMetafieldsArray = [
            // ...newProduct.product.metafields,
            { namespace: 'global', key: 'title_tag', type: 'string', value: `${newProduct.product.title} | ZYMO RESEARCH` },
            { namespace: 'global', key: 'description_tag', type: 'string', value: seo_description },
            ...highlights.map((highlight, index) => ({
                id: highlight.id,
                namespace: "highlight",
                key: `highlight-${String.fromCharCode(97 + index)}`,
                value: highlight.value,
                type: "string",
            })),
        ];

        // Dispatch action to update newProduct redux global state
        dispatch(productAdded({
            product: updatedProductData,
            metafields: updatedMetafieldsArray,
        }));

        // Save updated local form state to localStorage
        saveFormDataToLocalStorage(updatedProductData, updatedMetafieldsArray)

        // Navigate to previous step
        navigate("/new-product/step1", { replace: true });
    };

    const saveFormDataToLocalStorage = (updatedProductData, updatedMetafieldsArray) => {
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {}
        localStorage.setItem('newProduct', JSON.stringify(
            {
                product: {
                    ...newProduct.product,
                    ...updatedProductData,
                    images: storedData.product.images || [],
                    variants: storedData.product.variants || [],
                    metafields: updatedMetafieldsArray || [],
                    vendor: 'ZYMO RESEARCH',
                    status: 'active',
                    options: [
                        {
                            name: 'Name'
                        },
                        {
                            name: 'Size'
                        },
                        {
                            name: 'Shipping Delay',
                            values: [
                                'No Delay'
                            ]
                        }
                    ],
                }
            }
        ));
    };


    return (
        <section id="step-2" className="max-w-screen-md mx-auto p-6 m-16 bg-gray-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div id="step-2-container" className="mx-auto max-w-2xl">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div id="seo-container">
                        {/* <div className="pb-8">
                            <h2 className="text-xl font-bold mb-8">SEO Information</h2>
                            <div>
                                <label htmlFor='seo_title' className="block mb-2 font-medium">SEO Title</label>
                                <input disabled type='text' id="seo_title" className="w-4/5 border rounded py-2 px-3 mb-1"
                                    // value={newProduct.product.title}
                                // value={(storedData.product ? storedData.product.title : '')} 
                                />
                            </div>
                        </div> */}
                        <h2 className="text-xl font-bold mb-8">Product Details</h2>
                        <div className="pb-8">
                            <label htmlFor='seo_description' className="block mb-2 font-bold text-md">SEO Meta Description / Value Prop</label>
                            <p className="mb-3 text-sm">One-line product description (under the title of the product)</p>
                            <textarea
                                id="seo_description"
                                className="w-full md:w-4/5 border rounded py-2 px-3"
                                {...register("seo_description", {
                                    required: "Please enter a meta description",
                                    maxLength: { value: 160, message: "Meta description must be 160 characters or less" }
                                })}
                                onChange={handleTextareaChange}
                            />
                            <p>Characters Remaining: {160 - charCount}</p>
                            {errors.seo_description && <p className="text-red-500">{errors.seo_description.message}</p>}
                        </div>
                    </div>
                    <div id="highlights-container">
                        <label htmlFor='seo_description' className="block mb-2 font-bold text-md">Highlights</label>
                        <p className="mb-3 text-sm">List 3 key product highlights that are succinct. Use the product highlights in the finalized protocol as a guide.</p>
                        {highlights.map((highlight, index) => (
                            <div key={highlight.id} className="space-y-2 mb-5">
                                <label className="block mb-2 font-medium">Highlight {index + 1}</label>
                                <input
                                    type='text'
                                    {...register(`${highlight.id}`)}
                                    value={highlight.value}
                                    onChange={(e) => updateHighlight(highlight.id, e.target.value)}
                                    className="w-full md:w-4/5 border rounded py-2 px-3 mb-1"
                                />
                                <button type="button" onClick={() => deleteHighlight(highlight.id)} className="p-2 text-red-500 hover:text-red-600 font-bold">Delete</button>
                            </div>
                        ))}
                        <button type="button" onClick={addHighlight} className="mb-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Add a new highlight</button>
                    </div>
                    <div className="flex justify-between">
                        <button onClick={goBack} className="bg-gray-500 hover:bg-gray-400 text-white hover:text-gray-100 font-bold py-2 px-4 rounded">
                            Go Back
                        </button>
                        <button type="submit" className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                            Save & Next
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Step2;
