import React from 'react'

function DocumentsForm() {
    return (
        <section id="documents" className="mx-auto max-w-2xl mb-10 border-4 p-4">
            <h2 className="font-bold mb-2">Documents</h2>
            <p>Quick Protocol</p>
            <p>Datasheet</p>
            <p>SDS</p>
            <p>Supplemental Information</p>
        </section>
    )
}

export default DocumentsForm