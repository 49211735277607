// import React, { useEffect, useState } from 'react'
// import { findMetafield } from '../../helpers';

// function TechSpecsForm(productData) {
//     //https://stackoverflow.com/questions/39073118/shopify-rest-api-how-to-get-products-by-metafield

//     const productInfo = productData[0][0];
//     const productMetafields = productData[1].metafields;

//     // find metafields
//     // if namespace = specs
//     // render key 1st column in table
//     // render value 2nd column in table

//     const [specsArray, setSpecsArray] = useState([]);

//     useEffect(() => {
//         let specsArray = findMetafield(productMetafields, 'specs'); // returns specsArray
//         setSpecsArray(specsArray);

//     }, [productData])

//     return (
//         <section id="tech-specs" className="mx-auto max-w-2xl mb-10 border-4 p-4">
//             <h2 className="font-bold mb-2">Tech Specs</h2>


//             <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
//                 <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                     <tbody>
//                         {specsArray.map((spec) => (
//                             <tr key={spec.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                                 <th scope="row" className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-6 py-3">
//                                     {spec.key}
//                                 </th>
//                                 <td className="px-6 py-4">
//                                     {spec.value}
//                                 </td>
//                                 <td className="px-6 py-4 text-right">
//                                     <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
//                                 </td>
//                             </tr>

//                         ))}
//                         </tbody>

//                 </table>

//             </div>

//         </section>
//     )
// }

// export default TechSpecsForm;





//             {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
//                     <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                         <tbody>
//                             <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                                 <th scope="row" className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-6 py-3">
//                                     Product name
//                                 </th>
//                                 <td className="px-6 py-4">
//                                     Silver
//                                 </td>
//                                 <td className="px-6 py-4 text-right">
//                                     <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
//                                 </td>
//                             </tr>
//                             <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                                 <th scope="row" className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-6 py-3">
//                                     Product name
//                                 </th>
//                                 <td className="px-6 py-4">
//                                     Silver
//                                 </td>
//                                 <td className="px-6 py-4 text-right">
//                                     <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
//                                 </td>
//                             </tr>
//                             <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                                 <th scope="row" className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-6 py-3">
//                                     Product name
//                                 </th>
//                                 <td className="px-6 py-4">
//                                     Silver
//                                 </td>
//                                 <td className="px-6 py-4 text-right">
//                                     <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
//                                 </td>
//                             </tr>


//                         </tbody>
//                     </table>
//                 </div>



//                 <p>show specs table</p>
//                 metafields - namespace specs */}


// import React, { useEffect, useState } from 'react';
// import { findMetafield } from '../../helpers';

// function TechSpecsForm({ productData }) {
//     const productInfo = productData[0][0];
//     const productMetafields = productData[1].metafields;

//     const [specsArray, setSpecsArray] = useState([]);

//     useEffect(() => {
//         let specsArray = findMetafield(productMetafields, 'specs');
//         setSpecsArray(specsArray);
//     }, [productData])

//     return (
//         <section id="tech-specs" className="mx-auto max-w-2xl mb-10 border-4 p-4">
//             <h2 className="font-bold mb-2">Tech Specs</h2>
//             <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
//                 <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                     <tbody>
//                         {specsArray.map((spec) => (
//                             <tr key={spec.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
//                                 <th scope="row" className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-6 py-3">
//                                     {spec.key}
//                                 </th>
//                                 <td className="px-6 py-4">
//                                     {spec.value}
//                                 </td>
//                                 <td className="px-6 py-4 text-right">
//                                     <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </section>
//     );
// }

// export default TechSpecsForm;



import React, { useEffect, useState } from 'react';
import { findMetafield } from '../../helpers';

function TechSpecsForm({ productData }) {
    const productInfo = productData[0][0];
    const productMetafields = productData[1].metafields;

    const [specsArray, setSpecsArray] = useState([]);
    const [editingId, setEditingId] = useState(null); // State to track which row is being edited
    const [updatedSpecs, setUpdatedSpecs] = useState({}); // State to store updated values

    useEffect(() => {
        let specsArray = findMetafield(productMetafields, 'specs');
        setSpecsArray(specsArray);
    }, [productData]);

    // Function to handle editing of a specific row
    const handleEdit = (id) => {
        setEditingId(id);
        // Initialize updatedSpecs with the current values for the row being edited
        const spec = specsArray.find((spec) => spec.id === id);
        setUpdatedSpecs({ ...updatedSpecs, [id]: { key: spec.key, value: spec.value } });
    };

    // Function to handle saving changes for a specific row
    const handleSave = (id) => {
        // Update the specsArray with the updated values
        const updatedArray = specsArray.map((spec) => {
            if (spec.id === id) {
                return { ...spec, ...updatedSpecs[id] };
            }
            return spec;
        });
        setSpecsArray(updatedArray);
        setEditingId(null); // Reset editing state
    };

    // Function to handle input change in edit mode
    const handleInputChange = (e, id) => {
        const { name, value } = e.target;
        setUpdatedSpecs({ ...updatedSpecs, [id]: { ...updatedSpecs[id], [name]: value } });
    };
    
    console.log('editing id:', editingId)
    console.log('updated specs:', updatedSpecs)

    return (
        <section id="tech-specs" className="mx-auto max-w-2xl mb-10 border-4 p-4">
            <h2 className="font-bold mb-2">Tech Specs</h2>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <tbody>
                        {specsArray.map((spec) => (
                            <tr key={spec.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th scope="row" className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-6 py-3">
                                    {editingId === spec.id ? (
                                        <input
                                            type="text"
                                            name="key"
                                            value={updatedSpecs[spec.id]?.key || spec.key}
                                            onChange={(e) => handleInputChange(e, spec.id)}
                                        />
                                    ) : (
                                        spec.key
                                    )}
                                </th>
                                <td className="px-6 py-4">
                                    {editingId === spec.id ? (
                                        <input
                                            type="text"
                                            name="value"
                                            value={updatedSpecs[spec.id]?.value || spec.value}
                                            onChange={(e) => handleInputChange(e, spec.id)}
                                        />
                                    ) : (
                                        spec.value
                                    )}
                                </td>
                                <td className="px-6 py-4 text-right">
                                    {editingId === spec.id ? (
                                        <button
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                            onClick={() => handleSave(spec.id)}
                                        >
                                            Save
                                        </button>
                                    ) : (
                                        <button
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                            onClick={() => handleEdit(spec.id)}
                                        >
                                            Edit
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default TechSpecsForm;
