import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { productAdded } from './newProductSlice';

const Step5 = () => {
  const newProduct = useSelector((state) => state.newProduct); // Get the current state of the product from redux
  const dispatch = useDispatch(); // Get dispatch function to dispatch actions to redux slice
  const navigate = useNavigate(); // Get navigation function for navigating between steps
  const { register, handleSubmit, formState: { errors }, setValue } = useForm(); // Get react-hook-form methods for handling form data and validation



  const saveFormDataToLocalStorage = (updatedProductData, updatedMetafieldsArray) => {
    // Retrieve existing data from local storage
    const existingData = JSON.parse(localStorage.getItem('newProduct')) || {};

    const mergedData = {
      product: {
        ...existingData.product,
        ...updatedProductData,
        metafields: [
          ...(existingData.product.metafields || []), // Include existing metafields
          ...updatedMetafieldsArray, // Include new metafields
        ]
      }
    };

    // Update local storage with the merged data
    localStorage.setItem('newProduct', JSON.stringify(mergedData));
  };

  useEffect(() => {

    // Load form data from local storage when the component mounts
    const formData = JSON.parse(localStorage.getItem('newProduct')) || {};

    // Populate form fields with data from local storage for product properties
    Object.keys(formData.product || {}).forEach(key => {
      setValue(key, formData.product[key]);
    });


  }, [setValue]);






  let metafields = newProduct.product.metafields

  // Create array with existing faq metafields
  let faqsCurrent = metafields.filter((metafield) =>
    metafield.namespace === "faq"
  );

  // State to manage new faqs
  const [faqsNew, setFaqsNew] = useState([]);

  // Function to add a new FAQ
  const addFaq = () => {
    const nextIndex = faqsNew.length + 1;
    const newFaq = {
      id: `faq${String(nextIndex).padStart(3, '0')}`, // Generate FAQ ID like faq001, faq002, faq003, etc.
      namespace: 'local state - faq',
      key: `local state - original key, faq${String(nextIndex).padStart(3, '0')}`,
      value: 'local state - value',
      type: "local state - string"
    };
    setFaqsNew([...faqsNew, newFaq]);
  };

  // Function to delete a FAQ
  const deleteFaq = (id) => {
    // Remove the FAQ with the given ID
    const updatedFaqs = faqsNew.filter((faq) => faq.id !== id);
    // Reassign keys for the remaining FAQs
    const reindexedFaqs = updatedFaqs.map((faq, index) => ({
      ...faq,
      id: `faq${String(index + 1).padStart(3, '0')}`, // Reindex the FAQ IDs
      key: `faq${String(index + 1).padStart(3, '0')}`, // Reindex the FAQ IDs
    }));
    // Update the state with the reindexed FAQs
    setFaqsNew(reindexedFaqs);
    console.log('reindexed faqs in delete function:', reindexedFaqs)
  };




  const onSubmit = async (data, event) => {

    // Update the product data
    const updatedProductData = {
      ...newProduct.product,
    };

    // Update the local state merging both current faqs and new faqs 
    console.log('in step5A submit function, faq local state', faqsCurrent)
    const allFaqs = [...faqsCurrent, ...faqsNew];


    // Update product metafields array to include current metafields and each FAQ metafield
    const updatedMetafieldsArray = [
      ...newProduct.product.metafields,
      ...allFaqs.map(({ id }) => ({
        namespace: "faq",
        key: id, // Use the key property from each FAQ
        value: `${data[`faq-${id}-value-question`]} --- ${data[`faq-${id}-value-answer`]}`,
        type: "string",
      }))
    ];


    // Dispatch an action to update the product with the new data
    dispatch(productAdded({
      product: updatedProductData,
      metafields: updatedMetafieldsArray,
    }));

    // Save the submitted form data to local storage
    saveFormDataToLocalStorage(updatedProductData, updatedMetafieldsArray);

    // Navigate to the next step
    // navigate("/new-product/confirmation", { replace: true });
    navigate("/new-product/step6", { replace: true });


    // going back...
    // if event.target clicked was the id=go back button, n navigate backwards
    // else then navigate forwards
    // event listener
    // this is WIP - back-button is currently not working
    // event.preventDefault();
    // const clickedButtonId = event.target.id;
    // if  (clickedButtonId === 'back-button') {
    //     // Navigate to the previous step
    //     // Implement your logic here to determine the URL of the previous step
    //     navigate("new-product/step4A", { replace: true });
    // } else if (clickedButtonId === 'next-button'){
    //     // Navigate to the next step
    //     // navigate("/new-product/confirmation", { replace: true });
    //     navigate("/new-product/step6A", { replace: true });
    // }
  };

  const goBack = () => {
    navigate("/new-product/step4", { replace: true });
};

  return (
    <section className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <h2 className="text-xl font-semibold mb-4">Add FAQs</h2>
      <form onSubmit={(event) => handleSubmit(onSubmit)(event)} className="space-y-4">

        <button type="submit" id="back-button" className="text-red-500 hover:text-red-600 font-bold">Go Back</button>
        <div id="faq-container">

          {/* Render input fields for each FAQ */}
          {faqsNew.map(({ id }, index) => (
            <div key={`faq-${id}`} className="space-y-2">
              <label>FAQ number: {index + 1}</label>

              <label htmlFor={`faq-${id}-value-question`} className="block mb-1">Question:</label>
              <input type='text' id={`faq-${id}-value-question`} className="w-full border rounded py-2 px-3"
                {...register(`faq-${id}-value-question`, {
                  required: {
                    value: true,
                    message: "Please enter a question"
                  }
                })}
              />
              {errors[`faq-${id}-value-question`] && <p>{errors[`faq-${id}-value-question`].message}</p>}

              <label htmlFor={`faq-${id}-value-answer`} className="block mb-1">Answer:</label>
              <input type='text' id={`faq-${id}-value-answer`} className="w-full border rounded py-2 px-3"
                {...register(`faq-${id}-value-answer`, {
                  required: {
                    value: true,
                    message: "Please enter an answer"
                  }
                })}
              />
              {errors[`faq-${id}-value-answer`] && <p>{errors[`faq-${id}-value-answer`].message}</p>}


              <button type="button" onClick={() => deleteFaq(id)} className="text-red-500 hover:text-red-600 font-bold">Delete</button>
            </div>
          ))}

          {/* Add button to add new FAQ */}
          <button type="button" onClick={addFaq} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Add a new FAQ</button>


          {/* Submit button */}
          <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Save & Next</button>
        </div>
      </form>

      <button onClick={goBack} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mb-4">
                Back to Step 4
            </button>
    </section>
  );
};

export default Step5;
