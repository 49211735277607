import React from 'react';
import Highlights from './Highlights';
import Tags from './Tags';
import ProductImages from './ProductImages';
import SEO from './SEO';
import GeneralInfo from './GeneralInfo';

function ProductOverview({ productData }) {
    if (!productData || productData.length < 1) {
        return null;
    }

    // const productInfo = productData[0][0];
    const productInfo = productData[0];
    const productMetafields = productData[1].metafields;

    
    console.log('productData on overview:', productData)
    console.log('productMetafields on overview:', productMetafields) // array of metafields
    console.log('productMetafields[0] on overview:', productMetafields[0]) // 1st metafield in array

    return (
        <section id="product-overview" className="mx-auto max-w-2xl mb-10 border-4 p-4">
            <h2 className="font-bold mb-2 text-3xl">Product Overview</h2>

            <GeneralInfo productInfo={productInfo} />

            <Tags productInfo={productInfo} />

            <Highlights productMetafields={productMetafields} />

            <SEO productMetafields={productMetafields} />

            <ProductImages productInfo={productInfo} />
        </section>
    )
}

export default ProductOverview;
