// import React, { useState, useEffect } from 'react';

// function ProductImages(productInfo) {
//     const [imagesArray, setImagesArray] = useState([]);

//     useEffect(() => {
//         setImagesArray(productInfo.images);
//     }, [productInfo]);

//     console.log('ImageArray is here:', imagesArray);
        
//     const handleImageChange = (event, index, field) => {
//         const newImagesArray = [...imagesArray];
//         newImagesArray[index][field] = event.target.value;
//         setImagesArray(newImagesArray);
//     };

//     return (
//         <div id='images-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//             <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">Images</h3>

//             {imagesArray.map((image, index) => (
//                 <div key={image.id} id='image-card-section' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//                     <div className='mb-5'>
//                         <label htmlFor={`image-position-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Position</label>
//                         <input
//                             type="text"
//                             value={image.position}
//                             id={`image-position-${index}`}
//                             onChange={(event) => handleImageChange(event, index, 'position')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>
//                     <div className="mb-5">
//                         <label htmlFor={`image-alt-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Alternate Text</label>
//                         <input
//                             type="text"
//                             value={image.alt}
//                             id={`image-alt-${index}`}
//                             onChange={(event) => handleImageChange(event, index, 'alt')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>
//                     <div className='mb-5'>
//                         <label htmlFor={`image-src-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Source</label>
//                         <input
//                             type="text"
//                             value={image.src}
//                             id={`image-src-${index}`}
//                             onChange={(event) => handleImageChange(event, index, 'src')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>
//                     <div className='mb-5'>
//                         <img src={image.src} alt={image.alt} />
//                     </div>
//                     <form className="max-w-lg mx-auto">
//                         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="user_avatar">Upload file</label>
//                         <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
//                         <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Upload a new image here</div>
//                     </form>
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default ProductImages;

// import React, { useState, useEffect } from 'react';

// function ProductImages({ productInfo }) { // Changed here
//     const [imagesArray, setImagesArray] = useState([]);

//     useEffect(() => {
//         if (productInfo && productInfo.images) { // Changed here
//             setImagesArray(productInfo.images); // Changed here
//         }
//     }, [productInfo]);

//     console.log('ImageArray is here:', imagesArray);
        
//     const handleImageChange = (event, index, field) => {
//         const newImagesArray = [...imagesArray];
//         newImagesArray[index][field] = event.target.value;
//         setImagesArray(newImagesArray);
//     };

//     return (
//         <div id='images-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//             <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">Images</h3>

//             {imagesArray.map((image, index) => (
//                 <div key={image.id} id='image-card-section' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//                     <div className='mb-5'>
//                         <label htmlFor={`image-position-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Position</label>
//                         <input
//                             type="text"
//                             value={image.position}
//                             id={`image-position-${index}`}
//                             onChange={(event) => handleImageChange(event, index, 'position')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>
//                     <div className="mb-5">
//                         <label htmlFor={`image-alt-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Alternate Text</label>
//                         <input
//                             type="text"
//                             value={image.alt}
//                             id={`image-alt-${index}`}
//                             onChange={(event) => handleImageChange(event, index, 'alt')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>
//                     <div className='mb-5'>
//                         <label htmlFor={`image-src-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Source</label>
//                         <input
//                             type="text"
//                             value={image.src}
//                             id={`image-src-${index}`}
//                             onChange={(event) => handleImageChange(event, index, 'src')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>
//                     <div className='mb-5'>
//                         <img src={image.src} alt={image.alt} />
//                     </div>
//                     <form className="max-w-lg mx-auto">
//                         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="user_avatar">Upload file</label>
//                         <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
//                         <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Upload a new image here</div>
//                     </form>
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default ProductImages;

import React, { useState, useEffect } from 'react';

function ProductImages({ productInfo }) {
    const [imagesArray, setImagesArray] = useState([]);

    useEffect(() => {
        if (productInfo && productInfo.images) {
            // Ensure that each image object has the required fields
            const initialImages = productInfo.images.map(image => ({
                position: image.position || '', // Provide default value for position
                alt: image.alt || '', // Provide default value for alt
                src: image.src || '', // Provide default value for src
            }));
            setImagesArray(initialImages);
        }
    }, [productInfo]);

    const handleImageChange = (event, index, field) => {
        const newImagesArray = [...imagesArray];
        newImagesArray[index][field] = event.target.value;
        setImagesArray(newImagesArray);
    };

    return (
        <div id='images-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700">
            <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">Images</h3>

            {imagesArray.map((image, index) => (
                <div key={index} id='image-card-section' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div className='mb-5'>
                        <label htmlFor={`image-position-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Position</label>
                        <input
                            type="text"
                            value={image.position}
                            id={`image-position-${index}`}
                            onChange={(event) => handleImageChange(event, index, 'position')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor={`image-alt-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Alternate Text</label>
                        <input
                            type="text"
                            value={image.alt}
                            id={`image-alt-${index}`}
                            onChange={(event) => handleImageChange(event, index, 'alt')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='mb-5'>
                        <label htmlFor={`image-src-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Source</label>
                        <input
                            type="text"
                            value={image.src}
                            id={`image-src-${index}`}
                            onChange={(event) => handleImageChange(event, index, 'src')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='mb-5'>
                        <img src={image.src} alt={image.alt} />
                    </div>
                    <form className="max-w-lg mx-auto">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="user_avatar">Upload file</label>
                        <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
                        <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Upload a new image here</div>
                    </form>
                </div>
            ))}
        </div>
    );
}

export default ProductImages;
