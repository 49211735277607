// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import { Disclosure } from '@headlessui/react'
// // import { ChevronRightIcon } from '@heroicons/react/20/solid'
// // import {
// //   ShoppingBagIcon,
// //   ListBulletIcon,
// //   DocumentTextIcon,
// //   FolderIcon,
// //   BeakerIcon,
// // } from '@heroicons/react/24/outline'
// // import ProductOverview from './ProductOverviewForm'
// // import TechSpecsForm from './TechSpecsForm'
// // import ResourcesForm from './ResourcesForm'
// // import DocumentsForm from './DocumentsForm'

// // const navigation = [
// //   {
// //     name: 'Product Overview',
// //     icon: ShoppingBagIcon,
// //     current: true,
// //     href: <ProductOverview />,
// //     children: [
// //       { name: 'Title', href: 'product-title-form' },
// //       { name: 'Description', href: '#' },
// //       { name: 'Highlights', href: '#' },
// //       { name: 'Status', href: '#' },
// //       { name: 'Handle', href: '#' },
// //       { name: 'Tags', href: '#' },
// //       { name: 'SEO', href: '#' },
// //       { name: 'Images', href: '#' },
// //     ],
// //   },
// //   {
// //     name: 'Variants',
// //     icon: ListBulletIcon,
// //     current: false,
// //     href: <Variants />,
// //   },
// //   {
// //     name: 'Technical Specifications',
// //     icon: BeakerIcon,
// //     current: false,
// //     href: <TechSpecsForm />,
// //   },
// //   {
// //     name: 'Resources',
// //     icon: FolderIcon,
// //     current: false,
// //     href: <ResourcesForm />,
// //     children: [
// //       { name: 'FAQ', href: '#' },
// //       { name: 'Kit components', href: '#' },
// //       { name: 'Video', href: '#' },
// //       { name: 'Reviews', href: '#' },
// //       { name: 'Citations', href: '#' },
// //     ],
// //   },
// //   {
// //     name: 'Documents',
// //     icon: DocumentTextIcon,
// //     current: false,
// //     href: <DocumentsForm />,
// //     children: [
// //       { name: 'Quick Protocol', href: '#' },
// //       { name: 'Datasheet', href: '#' },
// //       { name: 'SDS', href: '#' },
// //       { name: 'Supplemental Information', href: '#' },
// //     ],
// //   },

// // ]

// // function classNames(...classes) {
// //   return classes.filter(Boolean).join(' ')
// // }

// // export default function Navigation() {
// //   return (
// //     <div className=" sticky top-0 flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
// //       <div className="flex h-16 shrink-0 items-center">
// //         <img
// //           className="h-8 w-auto"
// //           src="https://cdn.shopify.com/s/files/1/0016/3731/8726/files/zymo-logo-no-text_8ef545b9-0d1d-49d7-84ac-f31f741f4022.png?v=1625810943"
// //           alt="Your Company"
// //         />
// //       </div>

// //       <nav className="flex flex-1 flex-col">
// //         <ul role="list" className="flex flex-1 flex-col gap-y-7">
// //           <li key={item.name}>
// //             {!item.children ? (
// //               <Link
// //                 to={item.href} // Use "to" instead of "href"
// //                 className={classNames(
// //                   item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
// //                   'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
// //                 )}
// //               >
// //                 <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
// //                 {item.name}
// //               </Link>
// //             ) : (
// //               <Disclosure as="div">
// //                 {({ open }) => (
// //                   <>
// //                     <Disclosure.Button
// //                       className={classNames(
// //                         item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
// //                         'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700'
// //                       )}
// //                     >
// //                       <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
// //                       {item.name}
// //                       <ChevronRightIcon
// //                         className={classNames(
// //                           open ? 'rotate-90 text-gray-500' : 'text-gray-400',
// //                           'ml-auto h-5 w-5 shrink-0'
// //                         )}
// //                         aria-hidden="true"
// //                       />
// //                     </Disclosure.Button>
// //                     <Disclosure.Panel as="ul" className="mt-1 px-2">
// //                       {item.children.map((subItem) => (
// //                         <li key={subItem.name}>
// //                           {/* 44px */}
// //                           <Disclosure.Button
// //                             as="a"
// //                             href={subItem.href}
// //                             className={classNames(
// //                               subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
// //                               'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
// //                             )}
// //                           >
// //                             {subItem.name}
// //                           </Disclosure.Button>
// //                         </li>
// //                       ))}
// //                     </Disclosure.Panel>
// //                   </>
// //                 )}
// //               </Disclosure>
// //             )}
// //           </li>
// //         </ul>
// //       </nav >
// //     </div >
// //   )
// // }


// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import { Disclosure } from '@headlessui/react';
// // import { ChevronRightIcon } from '@heroicons/react/20/solid';
// // import {
// //   ShoppingBagIcon,
// //   ListBulletIcon,
// //   DocumentTextIcon,
// //   FolderIcon,
// //   BeakerIcon,
// // } from '@heroicons/react/24/outline';
// // import ProductOverview from './ProductOverviewSection/ProductOverview';
// // import Variants from './VariantsSection/VariantsForm';
// // import TechSpecsForm from './TechSpecsSection/TechSpecsForm';
// // import ResourcesForm from './ResourcesSection/ResourcesForm';
// // import DocumentsForm from './DocumentsSection/DocumentsForm';

// // const navigation = [
// //   {
// //     name: 'Product Overview',
// //     icon: ShoppingBagIcon,
// //     current: true,
// //     href: <ProductOverview />,
// //     children: [
// //       { name: 'Title', href: <ProductOverview /> },
// //       { name: 'Description', href: <ProductOverview /> },
// //       { name: 'Highlights', href: <ProductOverview /> },
// //       { name: 'Status', href: <ProductOverview /> },
// //       { name: 'Handle', href: <ProductOverview /> },
// //       { name: 'Tags', href: <ProductOverview /> },
// //       { name: 'SEO', href: <ProductOverview /> },
// //       { name: 'Images', href: <ProductOverview /> },
// //     ],
// //   },
// //   {
// //     name: 'Variants',
// //     icon: ListBulletIcon,
// //     current: false,
// //     href: <Variants />,
// //   },
// //   {
// //     name: 'Technical Specifications',
// //     icon: BeakerIcon,
// //     current: false,
// //     href: <TechSpecsForm />,
// //   },
// //   {
// //     name: 'Resources',
// //     icon: FolderIcon,
// //     current: false,
// //     href: <ResourcesForm />,
// //     children: [
// //       { name: 'FAQ', href: <ResourcesForm /> },
// //       { name: 'Kit components', href: <ResourcesForm /> },
// //       { name: 'Video', href: <ResourcesForm /> },
// //       { name: 'Reviews', href: <ResourcesForm /> },
// //       { name: 'Citations', href: <ResourcesForm /> },
// //     ],
// //   },
// //   {
// //     name: 'Documents',
// //     icon: DocumentTextIcon,
// //     current: false,
// //     href: <DocumentsForm />,
// //     children: [
// //       { name: 'Quick Protocol', href: <DocumentsForm /> },
// //       { name: 'Datasheet', href: <DocumentsForm /> },
// //       { name: 'SDS', href: <DocumentsForm /> },
// //       { name: 'Supplemental Information', href: <DocumentsForm /> },
// //     ],
// //   },
// // ];

// // function classNames(...classes) {
// //   return classes.filter(Boolean).join(' ');
// // }

// // export default function Navigation() {
// //   return (
// //     <div className=" sticky top-0 flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
// //       <div className="flex h-16 shrink-0 items-center">
// //         <img
// //           className="h-8 w-auto"
// //           src="https://cdn.shopify.com/s/files/1/0016/3731/8726/files/zymo-logo-no-text_8ef545b9-0d1d-49d7-84ac-f31f741f4022.png?v=1625810943"
// //           alt="Your Company"
// //         />
// //       </div>

// //       <nav className="flex flex-1 flex-col">
// //         <ul role="list" className="flex flex-1 flex-col gap-y-7">
// //           {navigation.map((item) => (
// //             <li key={item.name}>
// //               {!item.children ? (
// //                 <Link
// //                   to={item.href} // Use "to" instead of "href"
// //                   className={classNames(
// //                     item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
// //                     'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
// //                   )}
// //                 >
// //                   <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
// //                   {item.name}
// //                 </Link>
// //               ) : (
// //                 <Disclosure as="div">
// //                   {({ open }) => (
// //                     <>
// //                       <Disclosure.Button
// //                         className={classNames(
// //                           item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
// //                           'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700'
// //                         )}
// //                       >
// //                         <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
// //                         {item.name}
// //                         <ChevronRightIcon
// //                           className={classNames(
// //                             open ? 'rotate-90 text-gray-500' : 'text-gray-400',
// //                             'ml-auto h-5 w-5 shrink-0'
// //                           )}
// //                           aria-hidden="true"
// //                         />
// //                       </Disclosure.Button>
// //                       <Disclosure.Panel as="ul" className="mt-1 px-2">
// //                         {item.children.map((subItem) => (
// //                           <li key={subItem.name}>
// //                             {/* 44px */}
// //                             <Disclosure.Button
// //                               as="a"
// //                               href={subItem.href}
// //                               className={classNames(
// //                                 subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
// //                                 'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
// //                               )}
// //                             >
// //                               {subItem.name}
// //                             </Disclosure.Button>
// //                           </li>
// //                         ))}
// //                       </Disclosure.Panel>
// //                     </>
// //                   )}
// //                 </Disclosure>
// //               )}
// //             </li>
// //           ))}
// //         </ul>
// //       </nav>
// //     </div>
// //   );
// // }


// import React from 'react';
// import { Disclosure } from '@headlessui/react';
// import { ChevronRightIcon } from '@heroicons/react/20/solid';
// import {
//   ShoppingBagIcon,
//   ListBulletIcon,
//   DocumentTextIcon,
//   FolderIcon,
//   BeakerIcon,
// } from '@heroicons/react/24/outline';

// const navigation = [
//   {
//     name: 'Product Overview',
//     icon: ShoppingBagIcon,
//     current: true,
//     targetId: 'product-overview', // ID of the targeted element
//   },
//   {
//     name: 'Variants',
//     icon: ListBulletIcon,
//     current: false,
//     targetId: 'variants', // ID of the targeted element
//   },
//   {
//     name: 'Technical Specifications',
//     icon: BeakerIcon,
//     current: false,
//     targetId: 'tech-specs', // ID of the targeted element
//   },
//   {
//     name: 'Resources',
//     icon: FolderIcon,
//     current: false,
//     targetId: 'resources', // ID of the targeted element
//   },
//   {
//     name: 'Documents',
//     icon: DocumentTextIcon,
//     current: false,
//     targetId: 'documents', // ID of the targeted element
//   },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// export default function Navigation() {
//   return (
//     <div className=" sticky top-0 flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
//       <div className="flex h-16 shrink-0 items-center">
//         <img
//           className="h-8 w-auto"
//           src="https://cdn.shopify.com/s/files/1/0016/3731/8726/files/zymo-logo-no-text_8ef545b9-0d1d-49d7-84ac-f31f741f4022.png?v=1625810943"
//           alt="Your Company"
//         />
//       </div>

//       <nav className="flex flex-1 flex-col">
//         <ul role="list" className="flex flex-1 flex-col gap-y-7">
//           {navigation.map((item) => (
//             <li key={item.name}>
//               <a
//                 href={`#${item.targetId}`} // Use anchor link with target ID
//                 className={classNames(
//                   item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
//                   'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
//                 )}
//               >
//                 <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
//                 {item.name}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// }






import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  ShoppingBagIcon,
  ListBulletIcon,
  DocumentTextIcon,
  FolderIcon,
  BeakerIcon,
} from '@heroicons/react/24/outline';

const navigation = [
  {
    name: 'Product Overview',
    icon: ShoppingBagIcon,
    current: true,
    targetId: 'product-overview', // ID of the targeted element
  },
  {
    name: 'Variants',
    icon: ListBulletIcon,
    current: false,
    targetId: 'variants', // ID of the targeted element
  },
  {
    name: 'Technical Specifications',
    icon: BeakerIcon,
    current: false,
    targetId: 'tech-specs', // ID of the targeted element
  },
  {
    name: 'Resources',
    icon: FolderIcon,
    current: false,
    targetId: 'resources', // ID of the targeted element
  },
  {
    name: 'Documents',
    icon: DocumentTextIcon,
    current: false,
    targetId: 'documents', // ID of the targeted element
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Function to handle smooth scrolling to the target element
function scrollToTarget(targetId) {
  const element = document.getElementById(targetId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export default function Navigation() {
  return (
    <div className=" sticky top-0 flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="h-8 w-auto"
          src="https://cdn.shopify.com/s/files/1/0016/3731/8726/files/zymo-logo-no-text_8ef545b9-0d1d-49d7-84ac-f31f741f4022.png?v=1625810943"
          alt="Your Company"
        />
      </div>

      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          {navigation.map((item) => (
            <li key={item.name}>
              <a
                href={`#${item.targetId}`}
                className={classNames(
                  item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
                )}
                onClick={() => scrollToTarget(item.targetId)} // Scroll to target when clicked
              >
                <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

