import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// the initial state will be the empty product object and array of metafields
// includes loading and error logs

const initialState = {
    product: {
        // id: "", 
        title: "",
        body_html: "",
        vendor: "ZYMO RESEARCH",
        product_type: "",
        // handle: "",
        tags: "",
        status: "active",
        variants: [
            // {
                // product_id: "",
                // price: "0.00",
                // sku: "",
                // option1: null,
                // option2: null,
                // option3: "No Delay",
                // weight: 0,
                // weight_unit: "lb",
                // inventory_management: "shopify",
                // inventory_policy: "continue",
                // inventory_quantity: 10000,
            // }
        ],
        options: [
            {
                name: "Name",
                // values: [
                //     ""
                // ]
            },
            {
                name: "Size",
                // values: [
                //     ""
                // ]
            },
            {
                // This should be a default option for all variants
                name: "Shipping Delay",
                values: [
                    "No Delay"
                ]
            }
        ],
        metafields: [
            // {
            //     namespace: "",
            //     key: "",
            //     value: "",
            //     type: "",
            // }
        ],
        images: [
            
        ]
    },

    loading: false,
    error: null
}
console.log('initial state:', initialState)

// const baseURL = 'http://localhost:2911/dev';
const baseURL = 'https://service-api-dev.zymoresearch.com';
// TODO: change base URL to dev website

const addNewProductToShopify = createAsyncThunk('newProduct/addNewProductToShopify', async (newProduct) => {
    try {

        // console.log('In addNewProductToShopify async function, newProduct: ', newProduct)
        console.log('In addNewProductToShopify async function, newProduct.product: ', newProduct.product)

        const response = await axios.post(`${baseURL}/postNewProductToShopify`, newProduct.product)
        // const response = await axios.post(`${baseURL}/postNewProductToShopify`, newProduct)
        console.log("In addNewProductToShopify async function, new product post request was successful, here is response.data", response.data)
        // response.data will return the entire product object created by shopify

        console.log('In addNewProductToShopify async function, here is response.data.product.id:', response.data.product.id)

        return response.data // this will be available in the payload 

    } catch (error) {
        console.error("In addNewProductToShopify async function, Error adding new product to shopify", error);
        throw error
    }
}
)



const newProductSlice = createSlice({
    name: "newProduct",
    initialState,
    reducers: {
        productAdded: (state, action) => {
            const { product, variants, metafields, options, images } = action.payload;
            state.product = product; // Update product object

            // Check if variants is an array before updating
            if (Array.isArray(variants)) {
                state.product.variants = [...variants]; // Update variants array
            }

            // Check if metafields is an array before updating
            if (Array.isArray(metafields)) {
                state.product.metafields = [...metafields]; // Update metafields array
            }
            // Check if options is an array before updating
            if (Array.isArray(options)) {
                state.product.options = [...options]; // Update options array
            }
            // Check if images is an array before updating
            if (Array.isArray(images)) {
                state.product.images = [...images]; // Update images array
            }
            // if (Array.isArray(variants)) {
            //     state.product.variants = variants; // Update variants array
            // }
        
            // Check if metafields is an array before updating
            // if (Array.isArray(metafields)) {
            //     state.product.metafields = metafields; // Update metafields array
            // }
        },
 
    },
    extraReducers: (builder) => {
        builder.addCase(addNewProductToShopify.pending, state => {
            state.loading = true
            state.error = null;
        })
        builder.addCase(addNewProductToShopify.fulfilled, (state, action) => {
            state.loading = false
            state.product = action.payload
            state.error = ''
        })
        builder.addCase(addNewProductToShopify.rejected, (state, action) => {
            state.loading = false
            state.product = []
            state.error = action.error.message
        })
    }
}
)


export const { productAdded } = newProductSlice.actions
export { addNewProductToShopify }
export default newProductSlice.reducer

