// import React, { useState, useEffect } from 'react';

// function VariantCard(productVariants) {

//     // productVariants is an object with objects
//     // convert object values into an array
//     const productVariantsArray = Object.values(productVariants);

//     console.log('Product Variants Array:', productVariantsArray)

//     const [variantsArray, setVariantsArray] = useState([]);

//     useEffect(() => {
//         setVariantsArray(productVariantsArray);
//     }, [productVariants]);

//     console.log('VariantsArray is here:', variantsArray);

//     const handleVariantChange = (event, index, field) => {
//         const newVariantsArray = [...variantsArray];
//         newVariantsArray[index][field] = event.target.value;
//         setVariantsArray(newVariantsArray);
//     };

//     console.log('Changed Variant', variantsArray)


//     return (
//         <div>
//             {variantsArray.map((variant, index) => (
//                 <div key={variant.id} id='variant-card' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    
//                     {/* Variant Position */}
//                     <h2>Variant {variant.position}</h2>
//                     {/* <div className='mb-5'>
//                         <label htmlFor={`variant-position-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Variant Position: {variant.position}</label>
//                         <input
//                             type="text"
//                             value={variant.position}
//                             id={`variant-position-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'position')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div> */}

//                     {/* Variant Title */}
//                     <div className="mb-5">
//                         <label htmlFor={`variant-title-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Variant Title</label>
//                         <input
//                             type="text"
//                             value={variant.title}
//                             id={`variant-alt-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'title')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>

//                     {/* Sku */}
//                     <div className='mb-5'>
//                         <label htmlFor={`variant-sku-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SKU:</label>
//                         <input
//                             type="text"
//                             value={variant.sku}
//                             id={`variant-sku-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'sku')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>

//                     {/* Price */}
//                     <div className='mb-5'>
//                         <label htmlFor={`variant-price-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price:</label>
//                         <input
//                             type="text"
//                             value={variant.price}
//                             id={`variant-price-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'price')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>

//                     {/* Option 1 - Name */}
//                     <div className='mb-5'>
//                         <label htmlFor={`variant-option1-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name:</label>
//                         <input
//                             type="text"
//                             value={variant.option1}
//                             id={`variant-option1-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'option1')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>

//                     {/* Option 2 - Size */}
//                     <div className='mb-5'>
//                         <label htmlFor={`variant-option2-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Size:</label>
//                         <input
//                             type="text"
//                             value={variant.option2}
//                             id={`variant-option2-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'option2')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>

//                     {/* Option 3 - shipping Delay (default to No Delay) */}
//                     <div className='mb-5'>
//                         <label htmlFor={`variant-option3-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shipping Delay:</label>
//                         <input
//                             type="text"
//                             value={variant.option3}
//                             id={`variant-option3-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'option3')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>

//                     {/* Weight in pounds */}
//                     <div className='mb-5'>
//                         <label htmlFor={`variant-weight-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Weight in lbs</label>
//                         <input
//                             type="text"
//                             value={variant.weight}
//                             id={`variant-weight-${index}`}
//                             onChange={(event) => handleVariantChange(event, index, 'weight')}
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                         />
//                     </div>



//                     {/* Variant Image */}
//                     <div className='mb-5'>
//                         <img src={variant.image_id} />
//                     </div>
//                     <form className="max-w-lg mx-auto">
//                         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="user_avatar">Upload file</label>
//                         <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
//                         <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Upload a new image here</div>
//                     </form>
//                 </div>
//             ))}


//         </div>
//     )
// }

// export default VariantCard;

import React, { useState, useEffect } from 'react';

function VariantCard({ productVariants }) { // Changed here

    // Convert object values into an array
    const productVariantsArray = Object.values(productVariants);

    const [variantsArray, setVariantsArray] = useState([]);
    console.log('Product Variants Array:', productVariantsArray)
    useEffect(() => {
        setVariantsArray(productVariantsArray);
    }, [productVariants]);

    const handleVariantChange = (event, index, field) => {
        const newVariantsArray = [...variantsArray];
        newVariantsArray[index][field] = event.target.value;
        setVariantsArray(newVariantsArray);
    };

    return (
        <div>
            {variantsArray.map((variant, index) => (
                <div key={variant.id} id='variant-card' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    
                    {/* Variant Title */}
                    <div className="mb-5">
                        <label htmlFor={`variant-title-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Variant Title</label>
                        <input
                            type="text"
                            value={variant.title}
                            id={`variant-title-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'title')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Sku */}
                    <div className='mb-5'>
                        <label htmlFor={`variant-sku-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SKU:</label>
                        <input
                            type="text"
                            value={variant.sku}
                            id={`variant-sku-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'sku')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Price */}
                    <div className='mb-5'>
                        <label htmlFor={`variant-price-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price:</label>
                        <input
                            type="text"
                            value={variant.price}
                            id={`variant-price-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'price')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Option 1 - Name */}
                    <div className='mb-5'>
                        <label htmlFor={`variant-option1-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name:</label>
                        <input
                            type="text"
                            value={variant.option1}
                            id={`variant-option1-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'option1')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Option 2 - Size */}
                    <div className='mb-5'>
                        <label htmlFor={`variant-option2-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Size:</label>
                        <input
                            type="text"
                            value={variant.option2}
                            id={`variant-option2-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'option2')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Option 3 - shipping Delay (default to No Delay) */}
                    <div className='mb-5'>
                        <label htmlFor={`variant-option3-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shipping Delay:</label>
                        <input
                            type="text"
                            value={variant.option3}
                            id={`variant-option3-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'option3')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Weight in pounds */}
                    <div className='mb-5'>
                        <label htmlFor={`variant-weight-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Weight in lbs</label>
                        <input
                            type="text"
                            value={variant.weight}
                            id={`variant-weight-${index}`}
                            onChange={(event) => handleVariantChange(event, index, 'weight')}
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    {/* Variant Image */}
                    <div className='mb-5'>
                        <img src={variant.image_id} alt={`Variant ${variant.position} Image`} />
                    </div>
                    <form className="max-w-lg mx-auto">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="user_avatar">Upload file</label>
                        <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
                        <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Upload a new image here</div>
                    </form>
                </div>
            ))}
        </div>
    )
}

export default VariantCard;
