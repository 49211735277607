import React, { useState } from 'react';

function SearchForm ({ onSearch }) {
  const [productSKU, setProductSKU] = useState();

  const handleInputChange = (event) => {
    setProductSKU(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the onSearch function with the productHandle
    onSearch(productSKU);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="productSKU" className="block text-sm font-medium leading-6 text-gray-900">Product SKU</label>
      <input 
        type="text"
        placeholder="Enter product SKU"
        value={productSKU}
        onChange={handleInputChange}
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      <button type="submit">Search</button>
      {/* {searchInitiated && !productSKU&& <p>Please enter a product SKU.</p>} */}
    </form>
  );
};

export default SearchForm;



















// import React, { useState } from 'react';

// function SearchForm ({ onSearch }) {
//   const [productHandle, setProductHandle] = useState();

//   const handleInputChange = (event) => {
//     setProductHandle(event.target.value);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Call the onSearch function with the productHandle
//     onSearch(productHandle);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <label htmlFor="productHandle" className="block text-sm font-medium leading-6 text-gray-900">Product Handle</label>
//       <input 
//         type="text"
//         placeholder="Enter product handle"
//         value={productHandle}
//         onChange={handleInputChange}
//         required
//         className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//         />
//       <button type="submit">Search</button>
//       {/* {searchInitiated && !productHandle && <p>Please enter a product handle.</p>} */}
//     </form>
//   );
// };

// export default SearchForm;

