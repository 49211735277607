// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import Navigation from '../components/Navigation';
// import ProductOverview from '../components/ProductOverviewSection/ProductOverview';
// import VariantsForm from '../components/VariantsSection/VariantsForm';
// import TechSpecsForm from '../components/TechSpecsSection/TechSpecsForm';
// import ResourcesForm from '../components/ResourcesSection/ResourcesForm';
// import DocumentsForm from '../components/DocumentsSection/DocumentsForm';


// function EditProductPage() {
//   const location = useLocation()

//   // const { title, id, image, body_html, handle, tags, status } = location.state[0];

//   const productData = location.state;

//   // console.log("location:", location)
//   // console.log('location state:', location.state)
//   // console.log('productInfo title', location.state[0][0].title)
//   // console.log('productData[0][0].title:', productData[0][0].title)

//   return (
//     <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8 mx-auto">

//       {/* <ProductOverview title={title} id={id} image={image} body_html={body_html} handle={handle} tags={tags} status={status} /> */}

//       <div className=" grid grid-cols-5">
//         <div className="col-span-1">
//           <Navigation />
//           {/* <Navigation handleItemClick={handleItemClick} /> */}
//         </div>
//         <div className="col-span-4">
//           <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl m-2">Edit Product Page</h1>
//           <ProductOverview {...productData} />
//           <VariantsForm {...productData} />
//           <TechSpecsForm {...productData} />
//           <ResourcesForm />
//           <DocumentsForm />
//         </div>
//         <button
//           type="button"
//           className="w-64 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//         >
//           Save Changes
//         </button>
//       </div>



//     </div>
//   )
// }

// export default EditProductPage;

import React from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation';
import ProductOverview from '../components/ProductOverviewSection/ProductOverview';
import VariantsForm from '../components/VariantsSection/VariantsForm';
import TechSpecsForm from '../components/TechSpecsSection/TechSpecsForm';
import ResourcesForm from '../components/ResourcesSection/ResourcesForm';
import DocumentsForm from '../components/DocumentsSection/DocumentsForm';


function EditProductPage() {
  const location = useLocation()

  const productData = location.state;

  console.log("location:", location)
  console.log('location state:', location.state)
  console.log('productInfo title', location.state[0].title)
  console.log('productData[0][0].title:', productData[0].title)

  return (
    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8 mx-auto">
      <div className=" grid grid-cols-5">
        <div className="col-span-1">
          <Navigation />
        </div>
        <div className="col-span-4">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl m-2">Edit Product Page</h1>
          <ProductOverview productData={productData} />
          <VariantsForm productData={productData} />
          <TechSpecsForm productData={productData} />
          <ResourcesForm />
          <DocumentsForm />
        </div>
        <button
          type="button"
          className="w-64 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save Changes
        </button>
      </div>
    </div>
  )
}

export default EditProductPage;
