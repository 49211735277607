import React from 'react';
import { Link } from 'react-router-dom';

function Homepage() {
    return (
        <div id="homepage" className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <section id="homepage-container" className="mx-auto max-w-2xl text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Product Page Interface App</h1>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                    Welcome to The Product Page Interface App, your go-to resource for managing products on our Zymo Test Website.
                </p>



                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">What would you like to do today?</p>
                <div className="flex flex-col justify-center items-center">
                    <div className="m-10">
                        <Link to="/new-product">
                            <button
                                type="button"
                                className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Add a new product
                            </button>
                        </Link>
                    </div>

                    <div>
                        <Link to="/search">
                            <button
                                type="button"
                                className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Edit an existing product
                            </button>
                        </Link>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Homepage;
