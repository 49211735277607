import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchForm from '../components/SearchForm';
import { Link } from 'react-router-dom';

function SearchPageSKU () {
  const [searchResults, setSearchResults] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false);


  const baseURL = 'http://localhost:2911/dev';

  const handleSearch = async (productSKU) => {
    try {
      const response = await axios.get(`${baseURL}/getProductsBySKU`, {
        params: { productSKU: productSKU },
      });

      // if the sku entered by user does not exist in shopify store, then searchResults = false;

      // console.log('Response.data', response.data);

      // Extract productInfo and productMetafields from the response data
      const { productInfo, productMetafields } = response.data;
      const productData = [productInfo, productMetafields]
      // const productData = response.data
      console.log('PRODUCT DATA:', response.data)

      setSearchResults(productData);
      setSearchInitiated(true);


      // console.log('search results:', searchResults)
      // console.log('Product Info:', productInfo);
      // console.log('Product Metafields:', productMetafields);


    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  useEffect(() => {
    // Perform logic that depends on searchResults here
    console.log('things are changing')
  }, [searchResults]);

console.log('search results:', searchResults)

  return (
    <div className="container mx-auto my-8 p-8 bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Search By Sku </h1>

      
      <SearchForm onSearch={handleSearch} />


      <h2>Search result:</h2>

      {/* fix bug to show if object is null or undefined, then show placeholder value. else show - has to do with useEffect? */}
      {!searchResults && searchInitiated ?
        <p>This product SKU does not exist.</p>
        :
        <Link to={"/edit-product"} state={searchResults}>
          <ul className="mt-4">
            
           <li>{searchResults[0]?.title}</li> 
          </ul>
        </Link>
      }

    </div>
  );
};

export default SearchPageSKU;