import "./App.css";
import Homepage from "./pages/Homepage";
import { Routes, Route } from 'react-router-dom';
import SearchPage from "./pages/SearchPage";
import SearchPageSKU from "./pages/SearchPageSKU";
import NewProductPage from "./pages/NewProductPage";
import EditProductPage from "./pages/EditProductPage";
// import ProductsList from "./components/ProductsList";
// import SearchPage from "./pages/SearchPage";
import Step1 from "./features/new-product/Step1";
import Step2 from "./features/new-product/Step2";
import Step3 from "./features/new-product/Step3";
import Step4 from "./features/new-product/Step4";
import Step5 from "./features/new-product/Step5";
import Step6 from "./features/new-product/Step6";
import ConfirmationPage from "./features/new-product/ConfirmationPage";
import ThankYouPage from "./features/new-product/ThankYouPage";


function App() {
  return (
    <div className="App">
      
      <Routes>
        <Route path='/' element={<Homepage />} />
        {/* <Route path='/search' element={<SearchPage />} /> */}
        <Route path='/search' element={<SearchPageSKU />} />

        <Route path='/new-product' element={<NewProductPage />} />
        <Route path='/new-product/step1' element={<Step1 />} />
        <Route path="/new-product/step2" element={<Step2 />} />
        <Route path="/new-product/step3" element={<Step3 />} />
        <Route path="/new-product/step4" element={<Step4 />} />
        <Route path="/new-product/step5" element={<Step5 />} />
        <Route path="/new-product/step6" element={<Step6 />} />
        <Route path="/new-product/confirmation" element={<ConfirmationPage />} />
        <Route path="/new-product/thankyou" element={<ThankYouPage />} />

        <Route path='/edit-product' element={<EditProductPage />} />
      </Routes>
    
    </div>
  );
}

export default App;
