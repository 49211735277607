 // Helper Functions


export function removeHTMLTags(value) {
    // Use a regular expression to remove HTML tags
    const valueCleanSpaces = value.replaceAll('&nbsp;', ' ');
    const valueString = valueCleanSpaces.replace(/(<([^>]+)>)/gi, '');
    return valueString;
};


export function findMetafield(productMetafieldsArray, namespace) {

    // Must loop through all metafields to find correct namespace and/or key
    // Once correct namespace is found, push the metafield to namespaceArray
    // return the namespaceArray

    // Highlights
    if (namespace === 'highlight') {
        let highlightsArray = []

        for (let i = 0; i < productMetafieldsArray.length; i++) {

            if (productMetafieldsArray[i].namespace === "highlight") {
                let highlight = productMetafieldsArray[i]
                highlightsArray.push(highlight)
            }
        }
        // console.log('highlight Array:', highlightArray)
        return highlightsArray;
    }

    // Global Metafields - SEO title, SEO description, search_tags,search_type, category_tag
    if (namespace === 'global') {
        let globalArray = []

        for (let i = 0; i < productMetafieldsArray.length; i++) {

            if (productMetafieldsArray[i].namespace === "global") {
                let global = productMetafieldsArray[i]
                globalArray.push(global)
            }
        }
        // console.log('global Array:', globalArray)
        return globalArray;
    }

    // Tech Specs Metafields - tech specs table
    if (namespace === 'specs') {
        let specsArray = []

        for (let i = 0; i < productMetafieldsArray.length; i++) {

            if (productMetafieldsArray[i].namespace === "specs") {
                let spec = productMetafieldsArray[i]
                specsArray.push(spec)
            }
        }
        console.log('specs Array:', specsArray)
        return specsArray;
    }
}