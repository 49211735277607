// import React, { useState, useEffect } from 'react';
// import { findMetafield, removeHTMLTags } from '../../helpers';

// function Highlights(productMetafields) {

//     // productMetafields is an object with objects (each metafield)
//     // convert object values (each metafields) into an array
//     const productMetafieldsArray = Object.values(productMetafields);
//     // console.log('Here is the metafield array:', productMetafieldsArray);

//     const [highlightsArray, setHighlightsArray] = useState([]);

//     useEffect(() => {
//         let highlightsArray = findMetafield(productMetafieldsArray, 'highlight'); // returns highlightArray
//         setHighlightsArray(highlightsArray);

//     }, [productMetafields]);

//     const handleHighlightChange = (event, index) => {
//         const newHighlightsArray = [...highlightsArray];
//         newHighlightsArray[index].value = event.target.value;
//         setHighlightsArray(newHighlightsArray);
//     };

//     return (
//         <div className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//             <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">Highlights</h3>

//             {highlightsArray.map((highlight, index) => (
//                 <div key={highlight.key} id='highlight-card-section' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//                     <div className="mb-5">
//                         <label htmlFor={`highlight-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{highlight.key}</label>
//                         <textarea 
//                             type="text" 
//                             rows="3" 
//                             value={removeHTMLTags(highlight.value)} 
//                             id={`highlight-${index}`} 
//                             onChange={(event) => handleHighlightChange(event, index)} 
//                             className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
//                         />
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default Highlights;

import React, { useState, useEffect } from 'react';
import { findMetafield, removeHTMLTags } from '../../helpers';

function Highlights({ productMetafields }) {
    const productMetafieldsArray = Object.values(productMetafields);
    const [highlightsArray, setHighlightsArray] = useState([]);

    useEffect(() => {
        let highlightsArray = findMetafield(productMetafieldsArray, 'highlight');
        setHighlightsArray(highlightsArray);
    }, [productMetafields]);

    const handleHighlightChange = (event, index) => {
        const newHighlightsArray = [...highlightsArray];
        newHighlightsArray[index].value = event.target.value;
        setHighlightsArray(newHighlightsArray);
    };

    return (
        <div className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">Highlights</h3>

            {highlightsArray.map((highlight, index) => (
                <div key={highlight.key} id='highlight-card-section' className="max-w-screen-md mx-auto p-6 m-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div className="mb-5">
                        <label htmlFor={`highlight-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{highlight.key}</label>
                        <textarea 
                            type="text" 
                            rows="3" 
                            value={removeHTMLTags(highlight.value)} 
                            id={`highlight-${index}`} 
                            onChange={(event) => handleHighlightChange(event, index)} 
                            className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Highlights;