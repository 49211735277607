import React from 'react';
import { Link } from 'react-router-dom';

function NewProductPage() {
    return (
        <section id="new-product-page" className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div id="new-product-page-container" className="mx-auto max-w-2xl text-center">
                <h1 className="text-3xl font-bold mb-4">Add a New Product</h1>
                <div className="text-center mb-4">
                    <p>Please proceed through the following steps to add a new product to our Zymo Test Store.</p>
                    <p>If you have any questions or require assistance, kindly reach out to our web development team.</p>
                </div>

                <div className="flex justify-between">
                    <Link to="/">
                        <button className="bg-gray-500 hover:bg-gray-400 text-white hover:text-gray-100 font-bold py-2 px-4 rounded">
                            
                            Go Back
                        </button>
                    </Link>

                    <Link to="/new-product/step1">
                        <button
                            type="button"
                            className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        >
                            Continue
                        </button>
                    </Link>
                </div>
            </div>

        </section>

    )
}

export default NewProductPage;
