// import React, { useState, useEffect } from 'react'

// function Tags(productInfo) {

//     // show all checkboxes to check and uncheck 
//     // what other tags to add
//     // button to add a tag

//     //need to fix this
//     // should I have default checkboxes? ex) isKit, blue ice, etc

//     const [tags, setTags] = useState([]);

//     // State to keep track of the checked status of each tag
//     const [checkedTags, setCheckedTags] = useState({});

//     useEffect(() => {
//         if (productInfo && productInfo.tags) {
//             setTags(productInfo.tags.split(","));
//         }
//     }, [productInfo]);


//     // Function to handle checkbox change
//     function handleCheckboxChange (tag) {
//         setCheckedTags({
//             ...checkedTags,
//             [tag]: !checkedTags[tag] // Toggle the checked status
//         });
//     };


//     // console.log('Tags are here:', tags);


//     return (
//         <div>
//             {tags.map((tag) => (
//                 <div key={tag} className="flex items-center mb-4">
//                     <input
//                         id={tag}
//                         type="checkbox"
//                         className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
//                         checked={checkedTags[tag] || true}
//                         onChange={() => handleCheckboxChange(tag)}
//                     />
//                     <label htmlFor={tag} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{tag}</label>
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default Tags

import React, { useState, useEffect } from 'react';

function Tags({ productInfo }) { // Changed here

    const [tags, setTags] = useState([]);
    const [checkedTags, setCheckedTags] = useState({});

    useEffect(() => {
        if (productInfo && productInfo.tags) {
            setTags(productInfo.tags.split(","));
        }
    }, [productInfo]);

    function handleCheckboxChange(tag) {
        setCheckedTags({
            ...checkedTags,
            [tag]: !checkedTags[tag]
        });
    };

    return (
        <div>
            {tags.map((tag) => (
                <div key={tag} className="flex items-center mb-4">
                    <input
                        id={tag}
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={checkedTags[tag] || false} // Changed here
                        onChange={() => handleCheckboxChange(tag)}
                    />
                    <label htmlFor={tag} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{tag}</label>
                </div>
            ))}
        </div>
    );
}

export default Tags;
