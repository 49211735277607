import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { productAdded } from './newProductSlice';

const Step1 = () => {

    // Component State
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState('');;


    // Hooks
    const newProduct = useSelector((state) => state.newProduct); // Get the current state of the product from redux slice
    const dispatch = useDispatch(); // Get dispatch function to dispatch actions to redux slice
    const navigate = useNavigate(); // Get navigation function for navigating between steps from react-router-dom
    const { register, handleSubmit, formState: { errors }, setValue } = useForm(); // Get react-hook-form methods for handling form data and validation

    useEffect(() => {
        // Get stored data from local storage when the component mounts
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {};

        // Get and set the values for existing data from local storage when the component mounts
        if (storedData.product) {
            const existingSelectedTags = storedData.product.tags || "";
            const parsedTags = existingSelectedTags.split(",").map(tag => tag.trim()); // Convert the tags string into an array and trim white space
            const filteredTags = parsedTags.filter(tag => tag !== ''); // Remove any empty strings from the tags array
            setSelectedTags(filteredTags); // Set the stored tags string from local storage to array

            // console.log("Selected tags after setting state:", selectedTags);

            // Set other stored data from local storage
            setSelectedProductType(storedData.product.product_type || '');
            setValue('title', storedData.product.title || '');
            setValue('body_html', storedData.product.body_html || '');
            setValue('product_type', storedData.product.product_type || '');

            // Check if the image URL already exists in the localStorage an setValue 
            // TODO: Create feature to add more product images to an array
            const existingImage = storedData.product.images && storedData.product.images.some(
                (image) => image.src === storedData.product.product_image
            );

            if (storedData.product.images && storedData.product.images[0] && !existingImage) {
                setValue('product_image', storedData.product.images[0].src || '');
            }

        }
    }, []);


    // Event Handlers and Helper Functions
    const handleTagSelection = (e) => {
        // Get the value (tag) of the checkbox that triggered the event
        const tag = e.target.value;

        // Check if the checkbox is checked
        if (e.target.checked) {
            // If checked, add the tag to the selectedTags array
            setSelectedTags([...selectedTags, tag]);
        } else {
            // If unchecked, remove the tag from the selectedTags array
            setSelectedTags(selectedTags.filter(item => item !== tag));
        }
    };

    const onSubmit = (data) => {
        // Update product data in local form state
        const updatedProductData = {
            ...newProduct.product,
            title: data.title,
            body_html: data.body_html,
            product_type: selectedProductType,
            tags: selectedTags.join(", ") // Convert the selected tags array by combining selected tags into a string
        };


        // Check if image src already exists in redux state
        // Update images array in local form state
        let imagesArray = [];
        if (data.product_image && data.product_image !== "") {            // Check if the image URL already exists in the stored images array
            const imageExists = newProduct.product.images.some(
                (image) => image.src === data.product_image
            );

            // Add the image to the array only if it doesn't already exist
            if (!imageExists) {
                imagesArray.push({
                    src: data.product_image,
                    alt: data.title
                });
            }
        }

        const updatedImagesArray = [...(newProduct.product.images || []), ...imagesArray];


        // TODO: Add feature to add and delete main images in initial POST request
        // TODO: Add feature for user to add variant images. User can only add variant images after the variants are made.
        // Variant images can be added in a separate POST request using the existing variant id

        // Dispatch action to update newProduct redux global state
        dispatch(productAdded({
            product: updatedProductData,
            images: updatedImagesArray
        }));

        // Save updated local form state to localStorage
        saveFormDataToLocalStorage(updatedProductData, updatedImagesArray);

        // Navigate to the next step
        navigate("/new-product/step2", { replace: true });

    };
    // Function to set initial JSON data and to save updated local form data to localStorage
    const saveFormDataToLocalStorage = (updatedProductData, updatedImagesArray) => {

        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {}
        localStorage.setItem('newProduct', JSON.stringify(
            {
                product: {
                    ...newProduct.product,
                    ...updatedProductData,
                    images: updatedImagesArray || [],
                    variants: storedData.product ? [...storedData.product.variants] : [], // Check if storedData.product.variants exists
                    metafields: storedData.product ? [...storedData.product.metafields] : [], // Check if storedData.product.metafields exists
                    vendor: 'ZYMO RESEARCH',
                    status: 'active',
                    options: [
                        {
                            name: 'Name'
                        },
                        {
                            name: 'Size'
                        },
                        {
                            name: 'Shipping Delay',
                            values: [
                                'No Delay'
                            ]
                        }
                    ],
                }
            }
        ));



        // const mergedData = {
        //     product: {
        //         ...newProduct.product,
        //         ...updatedProductData,
        //         images: updatedImagesArray,
        //         variants: [...storedData.product.variants] || [],
        //         options: [...storedData.product.options] || [],
        //         metafields: [...storedData.product.metafields] || [],
        //     }
        // };

        // localStorage.setItem('newProduct', JSON.stringify(mergedData));

    };

    // Function for back button
    const goBack = () => {
        navigate("/new-product", { replace: true });
    };

    return (
        <section id="step-1" className="max-w-screen-md mx-auto p-6 m-16 bg-gray-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div id="step-1-container" className="mx-auto max-w-2xl">
                <h2 className="text-xl font-bold mb-8">General Production Information</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4 flex-grow">
                    <div className="pb-8">
                        <label htmlFor='title' className="block mb-2 font-bold text-md">Product Title</label>
                        <input type='text' id="title" className="w-full md:w-4/5 border rounded py-2 px-3 mb-1"
                            {...register("title", { required: "Please enter a product title" })}
                        />
                        {errors.title && <p className="text-red-500">{errors.title.message}</p>}
                    </div>
                    <div className="pb-8">
                        <label htmlFor='body_html' className="block mb-2 font-bold text-md">Product Description</label>
                        <p className="mb-3 text-sm">Write a descriptive paragraph detailing the benefits and core functionality of your product.<br />Try to use keywords twice in the first 2 sentences.</p>
                        <textarea id="body_html" className="w-full md:w-4/5 border rounded py-2 px-3"
                            {...register("body_html", { required: "Please enter a product description" })}
                        />
                        {errors.body_html && <p className="text-red-500">{errors.body_html.message}</p>}
                    </div>
                    <div className="pb-8">
                        <label htmlFor='product_type' className="block mb-2 font-bold text-md">Product Type</label>
                        <select
                            id="product_type"
                            className={"w-full md:w-72 border rounded py-2 px-3 mb-1" + (errors.product_type ? ' border-red-500' : '')}
                            {...register("product_type", { required: "Please select a product type" })}
                            value={selectedProductType}
                            onChange={(e) => setSelectedProductType(e.target.value)}
                        >
                            <option value="">Select Product Type</option>
                            <option value="Buffers">Buffers</option>
                            <option value="Columns, Plastics & Accessories">Columns, Plastics & Accessories</option>
                            <option value="DNA">DNA</option>
                            <option value="DNA/RNA Co-Purification">DNA/RNA Co-Purification</option>
                            <option value="Devices & Instruments">Devices & Instruments</option>
                            <option value="E. coli">E. coli</option>
                            <option value="Enzymes">Enzymes</option>
                            <option value="Epigenetics">Epigenetics</option>
                            <option value="Microbiomics">Microbiomics</option>
                            <option value="PCR">PCR</option>
                            <option value="Plasmid Purification">Plasmid Purification</option>
                            <option value="Protein">Protein</option>
                            <option value="RNA">RNA</option>
                            <option value="Sample Collection">Sample Collection</option>
                            <option value="Service">Service</option>
                            <option value="Special">Special</option>
                            <option value="Yeast">Yeast</option>
                            <option value="Other">Other</option>
                        </select>
                        {errors.product_type && <p className="text-red-500">{errors.product_type.message}</p>}
                    </div>

                    <div className="pb-8">
                        <label htmlFor='product_image' className="block mb-2 font-bold text-md">Product Image Link</label>
                        <input type='text' id="product_image" className="w-full md:w-4/5 border rounded py-2 px-3 mb-1"   {...register("product_image", {
                            pattern: {
                                value: /^(ftp|http|https):\/\/[^ "]+$/, // Regex to check if input is a URL link
                                message: "Please enter a valid image URL beginning with https://"
                            }
                        })} />
                        {errors.product_image && <p className="text-red-500">{errors.product_image.message}</p>}
                    </div>
                    <div className="pb-8">
                        <label className="block mb-2 font-bold text-md">Other</label>
                        <div className="space-y-2">
                            <div>
                                <input type="checkbox" id="blue-ice" value="BLUE ICE" checked={selectedTags && selectedTags.includes("BLUE ICE")} onChange={handleTagSelection} className="form-checkbox text-zymo-green" />
                                <label htmlFor="blue-ice"> Check this box if this product requires blue ice.</label>
                            </div>
                            <div>
                                <input type="checkbox" id="dry-ice" value="DRY ICE" checked={selectedTags && selectedTags.includes("DRY ICE")} onChange={handleTagSelection} className="form-checkbox text-zymo-green" />
                                <label htmlFor="dry-ice"> Check this box if this product requires dry ice.</label>
                            </div>
                            <div>
                                <input type="checkbox" id="is-kit" value="isKit" checked={selectedTags && selectedTags.includes("isKit")} onChange={handleTagSelection} className="form-checkbox text-zymo-green" />
                                <label htmlFor="is-kit"> Check this box if this product is a Kit.</label>
                            </div>
                        </div>
                    </div>
                    {errors.tags && <p className="text-red-500">{errors.tags.message}</p>}

                    <div className="flex justify-between">
                        {/* Back to Home button */}
                        <button onClick={goBack} className="bg-gray-500 hover:bg-gray-400 text-white hover:text-gray-100 font-bold py-2 px-4 rounded">
                            Go Back
                        </button>
                        {/* Save & Next button */}
                        <button type="submit" className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                            Save & Next
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}


export default Step1;