import React from 'react';
import { Link } from 'react-router-dom';

const ThankYouPage = () => {
  return (
    <>
    <div>Your new product has been added to the test website. Thank you!</div>
    <Link
        to="/"
        className="text-blue-500 hover:text-blue-700 font-bold"
      >
        Back to Home
      </Link>
    </>
  )
}

export default ThankYouPage;