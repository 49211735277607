import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { productAdded } from './newProductSlice';

const Step6 = () => {
    const newProduct = useSelector((state) => state.newProduct); // Get the current state of the product from redux
    const dispatch = useDispatch(); // Get dispatch function to dispatch actions to redux slice
    const navigate = useNavigate(); // Get navigation function for navigating between steps
    const { register, handleSubmit, formState: { errors }, setValue } = useForm(); // Get react-hook-form methods for handling form data and validation


    const [attachments, setAttachments] = useState([]);
    const [attachmentsCount, setAttachmentsCount] = useState(0);

    // Function to add a new attachment
    const addAttachment = () => {
        const newCount = attachmentsCount + 1;
        setAttachments([...attachments, { id: newCount }]);
        setAttachmentsCount(newCount);
    };

    // Function to delete an attachment
    const deleteAttachment = (id) => {
        const updatedAttachments = attachments.filter(attachment => attachment.id !== id);
        setAttachments(updatedAttachments);
    };



    const saveFormDataToLocalStorage = (updatedProductData, updatedMetafieldsArray) => {
        // Retrieve existing data from local storage
        const existingData = JSON.parse(localStorage.getItem('newProduct')) || {};
    
        const mergedData = {
          product: {
            ...existingData.product,
            ...updatedProductData,
            metafields: [
              ...(existingData.product.metafields || []), // Include existing metafields
              ...updatedMetafieldsArray, // Include new metafields
            ]
          }
        };
    
        // Update local storage with the merged data
        localStorage.setItem('newProduct', JSON.stringify(mergedData));
      };
    
      useEffect(() => {
    
        // Load form data from local storage when the component mounts
        const formData = JSON.parse(localStorage.getItem('newProduct')) || {};
    
        // Populate form fields with data from local storage for product properties
        Object.keys(formData.product || {}).forEach(key => {
          setValue(key, formData.product[key]);
        });
    
    
      }, [setValue]);






    const onSubmit = (data) => {
        const updatedProductData = {
            ...newProduct.product,
        };

        const attachmentsArray = attachments.map(({ id }) => ({
            namespace: "attachments",
            type: "string",
            key: data[`attachment-${id}-key`],
            value: data[`attachment-${id}-value`]
        }))

        const updatedMetafieldsArray = [...newProduct.product.metafields, ...attachmentsArray];


        // Dispatch an action to update the product with the new data
        dispatch(productAdded({
            product: updatedProductData,
            metafields: updatedMetafieldsArray,
        }));

        // Save the submitted form data to local storage
        saveFormDataToLocalStorage(updatedProductData, updatedMetafieldsArray);

        // Navigate to the next step
        navigate("/new-product/confirmation", { replace: true });
    };

    return (
        <section className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h2 className="text-xl font-semibold mb-4">Add attachments</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">

                {/* Render input fields for each attachment */}
                {attachments.map(({ id }) => (
                    <div key={`attachment-${id}`} className="space-y-2">
                        <label htmlFor={`attachment-${id}-key`} className="block mb-1">Document Type (Protocol, Quick Protocol, SDS, Datasheet, etc):</label>
                        <input type='text' id={`attachment-${id}-key`} className="w-full border rounded py-2 px-3"
                            {...register(`attachment-${id}-key`, {
                                required: {
                                    value: true,
                                    message: "Please choose a type of Attachment"
                                }
                            })}
                        />
                        {errors[`attachment-${id}-key`] && <p>{errors[`attachment-${id}-key`].message}</p>}


                        <label htmlFor={`attachment-${id}-value`} className="block mb-1">Link to PDF:</label>
                        <input type='text' id={`attachment-${id}-value`} className="w-full border rounded py-2 px-3"
                            {...register(`attachment-${id}-value`, {
                                required: {
                                    value: true,
                                    message: "Please enter a URL Link"
                                }
                            })}
                        />
                        {errors[`attachment-${id}-value`] && <p>{errors[`attachment-${id}-value`].message}</p>}

                        {/* Button to delete attachment */}
                        <button type="button" onClick={() => deleteAttachment(id)}>Delete</button>


                    </div>
                ))}

                {/* Button to add a new attachment */}
                <button type="button" onClick={addAttachment} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    Add a new attachment
                </button>

                {/* Submit button */}
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">Save & Next</button>
            </form>
        </section>
    );
};

export default Step6;
