import React from 'react'

function ResourcesForm() {
    return (
        <section id="resources" className="mx-auto max-w-2xl mb-10 border-4 p-4">
            <h2 className="font-bold mb-2">Resources</h2>
            {/* all are metafields */}
            <p>FAQ</p>
            <p>Kit components</p>
            <p>Product video</p>
            <p>Reviews</p>
            <p>Citations</p>
        </section>
    )
}

export default ResourcesForm