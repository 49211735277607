import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { productAdded } from './newProductSlice';

const Step4 = () => {
    // Component State
    const [specs, setSpecs] = useState([]);


    // Hooks
    const newProduct = useSelector((state) => state.newProduct);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();

    useEffect(() => {
        // Get stored data from local storage when the component mounts
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {};

        if (storedData.product && storedData.product.metafields) {
            const existingSpecs = storedData.product.metafields.filter(metafield => metafield.namespace === "specs") || [];
            setSpecs(existingSpecs);
        }

    }, []);


    const addSpec = () => {
        const newSpec = {
            id: uuidv4(),
            namespace: 'specs',
            key: '',
            value: '',
            type: "string"
        };
        setSpecs([...specs, newSpec]);
    };

    const updateSpec = (id, field, value) => {
        const updatedSpecs = specs.map(spec => {
            if (spec.id === id) {
                return { ...spec, [field]: value }; // Update the specified field for the matching spec
            }
            return spec;
        });
        setSpecs(updatedSpecs);
    };

    const deleteSpec = (id) => {
        const updatedSpecs = specs.filter(spec => spec.id !== id);
        setSpecs(updatedSpecs);
    };


    const onSubmit = (data) => {
        const updatedProductData = {
            ...newProduct.product,
        };

        // const specsArray = specs.map(({ id }) => ({
        //     namespace: "specs",
        //     type: "string",
        //     key: data[`spec-${id}-key`],
        //     value: data[`spec-${id}-value`]
        // }));

        const updatedMetafieldsArray = [
            // ...newProduct.product.metafields.filter(metafield => metafield.namespace !== "specs"),
            // ...specsArray
            ...newProduct.product.metafields,
            ...specs.map((spec) => ({
                id: spec.id,
                namespace: "specs",
                type: "string",
                key: data[`spec-${spec.id}-key`],
                value: data[`spec-${spec.id}-value`]
            }))
        ];

        dispatch(productAdded({
            product: updatedProductData,
            metafields: updatedMetafieldsArray,
        }));

        // Save the submitted form data to local storage
        saveFormDataToLocalStorage(updatedProductData, updatedMetafieldsArray);

        navigate("/new-product/step5", { replace: true });
    };


    const goBack = () => {
        const updatedProductData = {
            ...newProduct.product,
        };


        const updatedMetafieldsArray = [
            // ...newProduct.product.metafields.filter(metafield => metafield.namespace !== "specs"),
            // ...specsArray
            ...newProduct.product.metafields,
            ...specs.map((spec) => ({
                id: spec.id,
                namespace: "specs",
                type: "string",
                key: getValues(`spec-${spec.id}-key`),
                value: getValues(`spec-${spec.id}-value`),
            }))
        ];

        dispatch(productAdded({
            product: updatedProductData,
            metafields: updatedMetafieldsArray,
        }));

        // Save the submitted form data to local storage
        saveFormDataToLocalStorage(updatedProductData, updatedMetafieldsArray);

        navigate("/new-product/step3", { replace: true });
    };




    const saveFormDataToLocalStorage = (updatedProductData, updatedMetafieldsArray) => {
        const storedData = JSON.parse(localStorage.getItem('newProduct')) || {}
        localStorage.setItem('newProduct', JSON.stringify(
            {
                product: {
                    vendor: 'ZYMO RESEARCH',
                    status: 'active',
                    ...newProduct.product,
                    ...updatedProductData,
                    images: [...storedData.product.images] || [],
                    variants: [...storedData.product.variants] || [],
                    options: [
                        {
                            name: 'Name'
                        },
                        {
                            name: 'Size'
                        },
                        {
                            name: 'Shipping Delay',
                            values: [
                                'No Delay'
                            ]
                        }
                    ],
                    metafields: updatedMetafieldsArray
                }
            }
        ));
    };






    return (

        <section id="step-4" className="max-w-screen-md mx-auto p-6 m-16 bg-gray-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div id="step-4-container" className="mx-auto max-w-2xl">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <h2 className="text-xl font-bold mb-1">Product Technical Specifications</h2>
                    <p className="mb-8 text-sm">Copy and paste from the finalized protocol under “Specifications”.</p>


                    {specs.length > 0 && (
                        <table className="w-full table-auto border-collapse border border-slate-400">
                            <thead>
                                <tr className="text-left">
                                    <th className="px-4 py-2 border border-gray-300 bg-zymo-green text-white">Label</th>
                                    <th className="px-4 py-2 border-t border-b border-gray-300 bg-zymo-green text-white">Specification</th>
                                    <th className="px-4 py-2 border-r border-t border-b border-gray-300 bg-zymo-green text-white"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {specs.map((spec, index) => (
                                    // <tr key={spec.id} className="space-y-2 mb-5 p-5 border border-gray-300 rounded-lg bg-gray-300">
                                    <tr key={spec.id} className="p-5 border border-gray-300 rounded-lg bg-gray-100">
                                        <th className="p-3 border border-gray-300 rounded-lg bg-gray-100">
                                            {/* <label htmlFor={`spec-${spec.id}-key`} className="block mb-2 font-medium text-left">Label</label> */}
                                            <textarea type='text' id={`spec-${spec.id}-key`} className="w-full border rounded py-2 px-3 m-1 resize-y"
                                                {...register(`spec-${spec.id}-key`, {
                                                    required: "Please enter a spec label"
                                                })}
                                                value={spec.key} // Set the value of the input field to the spec key *LAST THING YOU CHANGEd*
                                                onChange={(e) => updateSpec(spec.id, 'key', e.target.value)}

                                            />
                                            {errors[`spec-${spec.id}-key`] && <p className="text-red-500">{errors[`spec-${spec.id}-key`].message}</p>}
                                        </th>
                                        <td className="p-3 border border-gray-300 rounded-lg bg-gray-100">
                                            {/* <label htmlFor={`spec-${spec.id}-value`} className="block mb-2 font-medium">Specification</label> */}
                                            <textarea type='text' id={`spec-${spec.id}-value`} className="w-full border rounded py-2 px-3 m-1 resize-y"
                                                {...register(`spec-${spec.id}-value`, {
                                                    required: "Please enter a spec description"

                                                })}
                                                value={spec.value} // Set the value of the input field to the spec value
                                                onChange={(e) => updateSpec(spec.id, 'value', e.target.value)}

                                            />
                                            {errors[`spec-${spec.id}-value`] && <p className="text-red-500">{errors[`spec-${spec.id}-value`].message}</p>}
                                        </td>
                                        <td>
                                            <button type="button" onClick={() => deleteSpec(spec.id)} className="p-2 text-red-500 hover:text-red-600 font-bold">Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <div className='mb-8'>
                        <button type="button" onClick={addSpec} className="mb-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                            Add a new spec
                        </button>
                    </div>

                    <div className="flex justify-between">
                        {/* Back to Home button */}
                        <button onClick={goBack} className="bg-gray-500 hover:bg-gray-400 text-white hover:text-gray-100 font-bold py-2 px-4 rounded">
                            Go Back
                        </button>
                        {/* Save & Next button */}
                        <button type="submit" className="w-auto bg-zymo-green hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                            Save & Next
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Step4;
