// import React from 'react';
// import VariantCard from './VariantCard';

// function VariantsForm(productData) {

//    const productInfo = productData[0][0];
//    const productVariants = productInfo.variants;
//    // const variantsOptions = productInfo.options; 

//    // console.log('product variants:', productData[0][0].variants)
//    // console.log('Variant Options array;', productInfo.options)


//    /* -------------------------------------------------------------
//       ****Note for Variant Options***

//       The Variants Array is a property of the productData object.
//       There is a separate Options Array that is also property of the productData object, which contains the option names ('Name', 'Size', 'Shipping Delay').
//       These option names cannot be accessed from the Variants array.
//       The Options Array can be accessed via:    // const variantsOptions = productInfo.options; 
   
//       When creating the POST form request, the options must be defaulted as so:

//             const productInfo = productData[0][0];
//             const variantsOptions = productInfo.options; 
//             variantOptions[0].name = "Name" (this is option1)
//             variantOptions[1].name = "Size" (this is option2)
//             variantOptions[1].name = "Shipping Delay" (this is option3)
//                variantOptions[1].values[0] = "No Delay" (this is the default option3 value)

//             Example of Options array: 
//                   productData.options [
//                      0: 
//                         id : 9148035301442
//                         name: "Name"
//                         position: 1
//                         product_id: 7030492758082
//                         values: ['Zymo-Seq RiboFree Total RNA Library Kit']
//                         [[Prototype]]: Object
//                      1: 
//                         id: 9148035334210
//                         name: "Size"
//                         position: 2
//                         product_id: 7030492758082
//                         values: Array(2)
//                            0: "12 Preps"
//                            1: "96 Preps" 
//                            length: 2
//                            [[Prototype]]: Array(0)
//                         [[Prototype]]: Object
//                      2: 
//                         id: 9148035366978
//                         name: "Shipping Delay"
//                         position: 3
//                         product_id: 7030492758082
//                         values: ['No Delay']
//                   ]   
//       ------------------------------------------------------------- */



//    return (
//       <div>
//          <section id="variants" className="mx-auto max-w-2xl mb-10 border-4 p-4">
//             <h2 className="font-bold mb-2 text-3xl">Variants</h2>
//             <VariantCard {...productVariants} />
//          </section >
//       </div>
//    )
// }

// export default VariantsForm;

import React from 'react';
import VariantCard from './VariantCard';

function VariantsForm({ productData }) { // Changed here

   // const productInfo = productData[0][0];
   const productInfo = productData[0];
   const productVariants = productInfo.variants;

   return (
      <div>
         <section id="variants" className="mx-auto max-w-2xl mb-10 border-4 p-4">
            <h2 className="font-bold mb-2 text-3xl">Variants</h2>
            <VariantCard productVariants={productVariants} /> {/* Changed here */}
         </section >
      </div>
   )
}

export default VariantsForm;
