// import React, { useState, useEffect } from 'react';
// import { findMetafield, removeHTMLTags } from '../../helpers';



// function SEO(productMetafields) {

//   // productMetafields is an object with objects (each metafield)
//   // convert object values (each metafields) into an array
//   const productMetafieldsArray = Object.values(productMetafields);
//   // console.log('Here is the metafield array:', productMetafieldsArray);

//   const [global, setGlobal] = useState([]);
//   const [SEOtitle, setSEOTitle] = useState('');
//   const [SEOdescription, setSEOdescription] = useState('');
//   const [searchTags, setSearchTags] = useState('');
//   const [categoryTag, setCategoryTag] = useState('');
//   const [searchType, setSearchType] = useState('');

//   useEffect(() => {
//     let globalArray = findMetafield(productMetafieldsArray, 'global'); // returns global Metafields array
//     setGlobal(globalArray);
//     // console.log(globalArray)

//     if (globalArray) {
//       for (let i = 0; i < globalArray.length; i++) {
//         if (globalArray[i].key === 'title_tag') {
//           setSEOTitle(globalArray[i].value || '');
//         };

//         if (globalArray[i].key === 'description_tag') {
//           setSEOdescription(globalArray[i].value || '');
//         };

//         if (globalArray[i].key === 'search_tag') {
//           setSearchTags(globalArray[i].value || '');
//         };

//         if (globalArray[i].key === 'category_tag') {
//           setCategoryTag(globalArray[i].value || '');
//         };

//         if (globalArray[i].key === 'search_type') {
//           setSearchType(globalArray[i].value || '');
//         };
//       };
//     };

//   }, [productMetafields]);



//   const handleSEOtitleChange = (event) => {
//     setSEOTitle(event.target.value);
//   };

//   const handleSEOdescriptionChange = (event) => {
//     setSEOdescription(event.target.value);
//   };

//   const handleSearchTagsChange = (event) => {
//     setSearchTags(event.target.value);
//   };
//   const handleCategoryTagChange = (event) => {
//     setCategoryTag(event.target.value);
//   };
//   const handleSearchTypeChange = (event) => {
//     setSearchType(event.target.value);
//   };

//   // console.log('Changed SEO title:', SEOtitle)
//   // console.log('Changed SEO description:', SEOdescription)
//   // console.log('Changed search tags:', searchTags)
//   // console.log('Changed category tag:', categoryTag)
//   // console.log('Changed search type:', searchType)


//   return (
//     <div id='SEO-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
//       <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">SEO Information</h3>

//       <div id='seo-form-container'> 
//         <div className="mb-5">
//           <label htmlFor="SEOtitle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SEO Title</label>
//           <input type="text" value={SEOtitle} id='title' onChange={handleSEOtitleChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//         </div>

//         <div className="mb-5">
//           <label htmlFor="SEOdescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SEO Description</label>
//           <textarea type="text" rows="3" value={SEOdescription} id='description' onChange={handleSEOdescriptionChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//         </div>


//         <div className="mb-5">
//           <label htmlFor="searchTags" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Search Tags</label>
//           <textarea type="text" rows="5" value={searchTags} id='handle' onChange={handleSearchTagsChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//         </div>

//         <div className="mb-5">
//           <label htmlFor="categoryTag" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category Tag</label>
//           <input type="text" value={categoryTag} id='handle' onChange={handleCategoryTagChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//         </div>

//         <div className="mb-5">
//           <label htmlFor="searchType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Search Type</label>
//           <input type="text" value={searchType} id='handle' onChange={handleSearchTypeChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//         </div>

//         {/* <button type="button" className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save Changes</button> */}

//       </div>
//     </div>
//   )
// }

// export default SEO;

import React, { useState, useEffect } from 'react';
import { findMetafield, removeHTMLTags } from '../../helpers';


function SEO({ productMetafields }) { // Changed here

  const productMetafieldsArray = Object.values(productMetafields);
  const [global, setGlobal] = useState([]);
  const [SEOtitle, setSEOTitle] = useState('');
  const [SEOdescription, setSEOdescription] = useState('');
  const [searchTags, setSearchTags] = useState('');
  const [categoryTag, setCategoryTag] = useState('');
  const [searchType, setSearchType] = useState('');

  useEffect(() => {
    let globalArray = findMetafield(productMetafieldsArray, 'global');
    setGlobal(globalArray);

    if (globalArray) {
      for (let i = 0; i < globalArray.length; i++) {
        if (globalArray[i].key === 'title_tag') {
          setSEOTitle(globalArray[i].value || '');
        };

        if (globalArray[i].key === 'description_tag') {
          setSEOdescription(globalArray[i].value || '');
        };

        if (globalArray[i].key === 'search_tag') {
          setSearchTags(globalArray[i].value || '');
        };

        if (globalArray[i].key === 'category_tag') {
          setCategoryTag(globalArray[i].value || '');
        };

        if (globalArray[i].key === 'search_type') {
          setSearchType(globalArray[i].value || '');
        };
      };
    };

  }, [productMetafields]);

  const handleSEOtitleChange = (event) => {
    setSEOTitle(event.target.value);
  };

  const handleSEOdescriptionChange = (event) => {
    setSEOdescription(event.target.value);
  };

  const handleSearchTagsChange = (event) => {
    setSearchTags(event.target.value);
  };
  const handleCategoryTagChange = (event) => {
    setCategoryTag(event.target.value);
  };
  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <div id='SEO-section' className="max-w-screen-md mx-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <h3 className=" text-xl mb-5 font-bold tracking-tight text-gray-900 dark:text-white">SEO Information</h3>

      <div id='seo-form-container'>
        <div className="mb-5">
          <label htmlFor="SEOtitle" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SEO Title</label>
          <input type="text" value={SEOtitle} id='title' onChange={handleSEOtitleChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>

        <div className="mb-5">
          <label htmlFor="SEOdescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SEO Description</label>
          <textarea type="text" rows="3" value={SEOdescription} id='description' onChange={handleSEOdescriptionChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>


        <div className="mb-5">
          <label htmlFor="searchTags" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Search Tags</label>
          <textarea type="text" rows="5" value={searchTags} id='handle' onChange={handleSearchTagsChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>

        <div className="mb-5">
          <label htmlFor="categoryTag" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category Tag</label>
          <input type="text" value={categoryTag} id='handle' onChange={handleCategoryTagChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>

        <div className="mb-5">
          <label htmlFor="searchType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Search Type</label>
          <input type="text" value={searchType} id='handle' onChange={handleSearchTypeChange} className="block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>
      </div>
    </div>
  )
}

export default SEO;
